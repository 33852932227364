import styled from 'styled-components';
import { ctaClickHandler } from '../../../lib/tracker';
import { formatUrl } from '../../../utils/formatUrl';
import { PageFragment } from '../../../wp-generated/types';
import LinkButton from '../../LinkButton';
import {
  chooseButtonColorBasedOnBackground,
  chooseLightOrDarkTextColor,
  chooseSecondaryButtonColorBasedOnPrimaryButton,
  getColorForCheckmark,
  moduleBackgroundToCSS,
} from '../getModuleColors';
import { Container, Kicker, ModuleFooter, Title, Wysiwyg } from './CommonStyles';

const Video = styled.video`
  z-index: -1;
  margin-left: calc(var(--site-padding) * -1);
  width: calc(var(--site-padding) * 2 + 100%);

  object-fit: cover;
  object-position: center center;
  @media (min-width: 1080px) {
    margin-left: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

const BgTitle = styled(Title)`
  max-width: 35rem;
`;
const BgKicker = styled(Kicker)`
  max-width: 35rem;
`;

const BgFooter = styled(ModuleFooter)`
  justify-content: flex-start;
`;
const BgWysiwyg = styled(Wysiwyg)`
  max-width: 35rem;
`;

const HeroBg = ({ data }: { data: NonNullable<PageFragment['hero']>['heroBg'] }) => {
  const videoUrl = data?.video?.node.mediaItemUrl;
  const buttonColor = chooseButtonColorBasedOnBackground(data?.bgColor);
  return (
    <Container
      data-type="bg"
      $backgroundcolor={videoUrl ? 'transparent' : moduleBackgroundToCSS(data?.bgColor)}
      $color={chooseLightOrDarkTextColor(data?.bgColor)}
      $bgimage={data?.image?.node?.mediaItemUrl || undefined}
      $bg
    >
      {videoUrl ? <Video src={videoUrl} autoPlay loop muted playsInline /> : null}
      <div>
        <header>
          {data?.kicker && <BgKicker>{data.kicker}</BgKicker>}
          <BgTitle dangerouslySetInnerHTML={{ __html: data?.title ?? '' }} />
        </header>
        <BgWysiwyg
          dangerouslySetInnerHTML={{ __html: data?.description ?? '' }}
          $primaryColor={getColorForCheckmark(data?.bgColor)}
        />
      </div>
      {data?.links && data?.links.length > 0 && (
        <BgFooter>
          {data?.links.map((link, i) => (
            <LinkButton
              key={link?.link?.url || i}
              href={formatUrl(link?.link?.url)}
              variant={
                i % 2 === 1
                  ? chooseSecondaryButtonColorBasedOnPrimaryButton(buttonColor)
                  : buttonColor
              }
              onClick={ctaClickHandler('hero')}
            >
              {link?.link?.title}
            </LinkButton>
          ))}
        </BgFooter>
      )}
    </Container>
  );
};

export default HeroBg;
