import { ReactComponent as Blogs } from './svgs/blogs.svg';
import { ReactComponent as Facebook } from './svgs/facebook.svg';
import { ReactComponent as Forums } from './svgs/forums.svg';
import { ReactComponent as Instagram } from './svgs/instagram.svg';
import { ReactComponent as Linkedin } from './svgs/linkedin.svg';
import { ReactComponent as Web } from './svgs/web.svg';
import { ReactComponent as Wordpress } from './svgs/wordpress.svg';
import { ReactComponent as X } from './svgs/x.svg';
import { ReactComponent as YouTube } from './svgs/youtube.svg';
import SvgWrapper from './SvgWrapper';

const wrapIcon =
  (Component: any, defaultColor: string) =>
  ({
    useDefaultColor = true,
    ...props
  }: {
    $square?: boolean;
    ariaLabel?: string;
    size?: string;
    disabled?: boolean;
    useDefaultColor?: boolean;
  }) => (
    <SvgWrapper {...props} color={useDefaultColor ? defaultColor : undefined}>
      <Component role="img" aria-label={props.ariaLabel} viewBox="0 0 40 40" />
    </SvgWrapper>
  );

const SocialIcons = {
  Blogs: wrapIcon(Blogs, 'black'),
  Facebook: wrapIcon(Facebook, '#1877F2'),
  Forums: wrapIcon(Forums, 'black'),
  LinkedIn: wrapIcon(Linkedin, '#0077B5'),
  Wordpress: wrapIcon(Wordpress, '#3E3D3A'),
  YouTube: wrapIcon(YouTube, '#FF0000'),
  Instagram: wrapIcon(Instagram, '#C23584'),
  Web: wrapIcon(Web, '#FC628F'),
  Twitter: wrapIcon(X, 'black'),
  'X (Twitter)': wrapIcon(X, 'black'),
};

export const SocialIconsDefault = () => <div />;
SocialIconsDefault.displayName = 'SocialIcons';

export default SocialIcons;
