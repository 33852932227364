import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ctaClickHandler, selectContentClickHandler } from '../../../lib/tracker';
import { formatUrl } from '../../../utils/formatUrl';
import { LatestPostsFragment } from '../../../wp-generated/types';
import LinkButton from '../../LinkButton';
import { PostTag } from '../../PostTag';
import {
  chooseButtonColorBasedOnBackground,
  chooseLightOrDarkTextColor,
  moduleBackgroundToCSS,
} from '../getModuleColors';

const Container = styled.section<{ $background: string; $color: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${({ $background }) => $background};
  color: ${({ $color }) => $color};
  padding: 1.25rem;
  @media (min-width: 1080px) {
    padding: var(--spacing-2x-large) var(--site-padding);
  }
`;

const ArticleList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-content: center;
  gap: 1.5rem;
  @media (min-width: 1080px) {
    gap: 2.5rem;
  }
`;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  h3 {
    margin: 0;
  }
  a {
    color: inherit;
  }
  a:hover {
    text-decoration-line: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;
  }
  a.linkButton {
    transform: translateX(-1rem);
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;

const Title = styled.h2`
  font-weight: var(--font-weight-extra-bold);
  text-align: center;
  width: 100%;
  letter-spacing: 1px;
  line-height: calc(var(--font-size-large) + 0.3125rem);
  text-transform: uppercase;
  color: inherit;
`;

const PostTags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const PostTitle = styled.h3`
  font-size: var(--font-size-x-large);
  font-weight: var(--font-weight-bold);
  line-height: calc(var(--font-size-x-large) + 0.3125rem);
  word-break: break-word;
  hyphens: auto;
`;

const ModuleFooter = styled.footer`
  display: flex;
  justify-content: center;
`;

const LatestPostsModule = ({ moduleData }: { moduleData: LatestPostsFragment }) => {
  const { t } = useTranslation();
  const textColor = chooseLightOrDarkTextColor(moduleData.bgColor);

  return (
    <Container
      $background={moduleBackgroundToCSS(moduleData.bgColor)}
      $color={textColor}
      data-bgcolor={moduleData.bgColor}
    >
      <Title>{moduleData.title}</Title>
      <ArticleList>
        {moduleData.data?.map((post, index) => (
          <Article key={index}>
            <ImgWrapper>
              <Img src={post?.image || ''} alt="" role="presentation" />
            </ImgWrapper>
            <PostTags>
              {post?.tags?.map((tag, index) => (
                <PostTag
                  key={index}
                  href={formatUrl(tag?.url)}
                  $background={moduleBackgroundToCSS(moduleData.bgColor)}
                  onClick={selectContentClickHandler(tag?.name ?? '', 'tag')}
                >
                  {tag?.name}
                </PostTag>
              ))}
            </PostTags>
            <PostTitle>
              {post?.url ? <a href={formatUrl(post?.url)}>{post?.title}</a> : post?.title || ''}
            </PostTitle>
            <time dateTime={post?.date || ''}>{post?.date}</time>
            <LinkButton
              href={formatUrl(post?.url)}
              variant="text"
              className="linkButton"
              onClick={ctaClickHandler('latest_posts')}
            >
              {t('common_read-more')}
            </LinkButton>
          </Article>
        ))}
      </ArticleList>
      {moduleData.links && moduleData.links.length > 0 && (
        <ModuleFooter>
          {moduleData.links.map((link, i) => (
            <LinkButton
              key={link?.link?.url || i}
              href={formatUrl(link?.link?.url)}
              variant={chooseButtonColorBasedOnBackground(moduleData.bgColor)}
              target={link?.link?.target || undefined}
              onClick={ctaClickHandler('latest_posts')}
            >
              {link?.link?.title}
            </LinkButton>
          ))}
        </ModuleFooter>
      )}
    </Container>
  );
};

export default LatestPostsModule;
