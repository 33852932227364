import React from 'react';
import { GroupBase, MultiValueProps } from 'react-select';
import styled, { keyframes } from 'styled-components';
import { Chip } from '../../../Chip';
import Icons from '../../../Icons';

type Props = MultiValueProps<OptionType, true, GroupBase<OptionType>> & {
  data: {
    label: string;
    value: any;
    variant?: 'primary' | 'secondary' | 'dark' | 'prAssistant';
  };
  selectProps: { dismissAriaLabel: string; operator: string; variant: 'primary' | 'secondary' };
  removeProps: {
    onClick: (data: { label: string; value: any }) => void;
    onTouchEnd?: (event: React.TouchEvent<HTMLButtonElement>) => void;
    onTouchStart?: (event: React.TouchEvent<HTMLButtonElement>) => void;
  };
  isDisabled: boolean;
};

const showMultiValue = keyframes`
  0% {
    opacity: 0.001;
    transform: scale(0.85);
  }
  90% {
    transform: scale(1);
  }
  100% {
    opacity: 1;
  }
`;

const TagContainer = styled.div`
  margin: 0.125rem;
  max-width: calc(100% - 0.25rem);
  animation: ${showMultiValue} 0.3s cubic-bezier(0.25, 0.55, 0.3, 1) forwards;
`;

const Operator = styled.span`
  margin: 0 0.5rem;
  &:first-child {
    display: none;
  }
`;
type OptionType = {
  label: string;
  value: any;
  icon?: React.ReactNode;
  automated?: boolean;
  isFixed?: boolean;
  testId?: string;
};

const MultiValue: React.FC<Props> = ({
  data,
  selectProps: { dismissAriaLabel, operator },
  removeProps: { onClick },
  isDisabled,
}) => (
  <>
    {operator && <Operator>{operator}</Operator>}
    <TagContainer>
      <Chip
        type="selection"
        onRemove={() => onClick(data as any)}
        buttonAriaLabel={dismissAriaLabel}
        disabled={isDisabled || data.isFixed}
        icon={data.automated ? <Icons.Sparkle /> : undefined}
      >
        {data.label}
      </Chip>
    </TagContainer>
  </>
);
export default MultiValue;
