
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "ActivateSimpleSearchProfilesError": [
      "ActivateSimpleSearchProfilesOnboardingCompletedError",
      "ActivateSimpleSearchProfilesUnauthorizedError",
      "ActivateSimpleSearchProfilesValidationError"
    ],
    "AddContactListError": [
      "AddContactListNetworkLabelNotFoundError",
      "AddContactListUnauthorizedError",
      "AddContactListValidationError"
    ],
    "AddHitToClipboardError": [
      "AddHitToClipboardUnauthorizedError",
      "AddHitToClipboardValidationError"
    ],
    "AnalyzeCreateExportError": [
      "AnalyzeCreateExportFailedError",
      "AnalyzeCreateExportInvalidEmailError",
      "AnalyzeCreateExportPressroomNotFoundError"
    ],
    "AudienceBuilderAdvancedSearchCreateError": [
      "AudienceBuilderAdvancedSearchCreateUnauthorizedError",
      "AudienceBuilderAdvancedSearchCreateValidationError"
    ],
    "AudienceBuilderAdvancedSearchDeleteError": [
      "AudienceBuilderAdvancedSearchDeleteNotFoundError",
      "AudienceBuilderAdvancedSearchDeleteUnauthorizedError"
    ],
    "AudienceBuilderSearchError": [
      "AgilityPrValidationError",
      "ApiServerError",
      "TimeoutError",
      "UnauthorizedError"
    ],
    "BlogPostUpdateError": [
      "BlogPostUpdateNotFoundError",
      "BlogPostUpdateSaveError",
      "BlogPostUpdateUnauthorizedError"
    ],
    "BulkDownloadError": [
      "BulkDownloadSaveError"
    ],
    "Channel": [
      "FacebookChannel",
      "LinkedinChannel",
      "PartnerChannel",
      "TwitterChannel",
      "YoutubeChannel"
    ],
    "ChannelAddError": [
      "ChannelAddNotFoundError",
      "ChannelAddUnauthorizedError",
      "ChannelAddUserAlreadyAddedError"
    ],
    "ChannelDisconnectError": [
      "ChannelDisconnectNotFoundError",
      "ChannelDisconnectUnauthorizedError"
    ],
    "ChannelReauthenticateError": [
      "ChannelReauthenticateNotFoundError",
      "ChannelReauthenticateUnauthorizedError"
    ],
    "ChannelUpdateError": [
      "ChannelUpdateUnauthorizedError",
      "ChannelUpdateValidationError"
    ],
    "CommercialProductPurchaseError": [
      "CommercialProductPurchaseRequestError",
      "CommercialProductPurchaseUnauthorizedError"
    ],
    "CommercialProductTrialError": [
      "CommercialProductTrialRequestError",
      "CommercialProductTrialUnauthorizedError"
    ],
    "ContactImport": [
      "AudienceTargeting",
      "ContactImportAgilityPR",
      "ContactImportAudienceList",
      "ContactImportFileUpload",
      "ContactImportPaste",
      "FindContact",
      "MediaRelations",
      "SuggestedContactsImport"
    ],
    "ContactImportMutationError": [
      "ContactListImportError",
      "ImportLimitReachedError",
      "KeywordOrParametricSearchImportError",
      "QueryImportError"
    ],
    "ContactListSaveError": [
      "ContactListSaveUnauthorizedError",
      "ContactListSaveValidationError"
    ],
    "ContactPersonConnectError": [
      "ContactPersonConnectAlreadyConnectedError",
      "ContactPersonConnectNotFoundError",
      "ContactPersonConnectUnauthorizedError"
    ],
    "ContactPersonDeleteError": [
      "ContactPersonDeleteUnauthorizedError"
    ],
    "ContactPersonDisconnectError": [
      "ContactPersonDisconnectNotFoundError",
      "ContactPersonDisconnectUnauthorizedError"
    ],
    "ContactPersonMoveError": [
      "ContactPersonMoveUnauthorizedError",
      "ContactPersonMoveValidationError"
    ],
    "ContactPersonRemoveImageError": [
      "ContactPersonRemoveImageNotFoundError",
      "ContactPersonRemoveImageSaveError",
      "ContactPersonRemoveImageUnauthorizedError"
    ],
    "ContactPersonUpdateError": [
      "ContactPersonUpdateNotFoundError",
      "ContactPersonUpdateUnauthorizedError",
      "ContactPersonUpdateValidationError"
    ],
    "ContactsFindJournalistImportError": [
      "ContactsFindJournalistImportNetworkLabelNotFoundError",
      "ContactsFindJournalistImportUnauthorizedError",
      "FindJournalistImportLimitReachedError"
    ],
    "ContactsImportAudienceListError": [
      "ContactsImportAudienceListNotFoundError",
      "ContactsImportAudienceListUnauthorizedError"
    ],
    "ContactsImportEmailListError": [
      "ContactsImportEmailListUnauthorizedError",
      "ContactsImportEmailListValidationError"
    ],
    "ContactsImportFileError": [
      "ContactsImportFileUnauthorizedError",
      "ContactsImportFileValidationError"
    ],
    "ContactsMediaRelationsImportError": [
      "ContactsMediaRelationsImportNetworkLabelNotFoundError",
      "ContactsMediaRelationsImportUnauthorizedError"
    ],
    "CopilotUrlSaveError": [
      "CopilotUrlSaveUnauthorizedError",
      "CopilotUrlSaveValidationError"
    ],
    "CopilotUrlUpdateError": [
      "CopilotUrlUpdateToFrequentError",
      "CopilotUrlUpdateUnauthorizedError"
    ],
    "CreateClipboardError": [
      "CreateClipboardUnauthorizedError",
      "CreateClipboardValidationError"
    ],
    "CreateNewsAlertError": [
      "CreateNewsAlertUnauthorizedError",
      "CreateNewsAlertValidationError"
    ],
    "CreateOrUpdateSearchGroupError": [
      "CreateOrUpdateSearchGroupUnauthorizedError",
      "CreateOrUpdateSearchGroupValidationError"
    ],
    "CreateSearchProfileError": [
      "CreateSearchProfileLimitExceededError",
      "CreateSearchProfileUnauthorizedError",
      "CreateSearchProfileValidationError"
    ],
    "CreateSimpleSearchProfileError": [
      "CreateSimpleSearchProfileUnauthorizedError",
      "CreateSimpleSearchProfileValidationError"
    ],
    "DashboardItem": [
      "AccountVerificationPending",
      "BrandReportBannerH12024",
      "BrandReportBought",
      "BrandReportNeverTried",
      "BrandReportTrial",
      "CoPilot",
      "GeneratedBrandReport",
      "LatestFollowers",
      "LatestMediaCoverage",
      "LatestMediaCoverageUnlocker",
      "LatestStory",
      "MostPopularMaterials",
      "NewsroomStatistics",
      "OnboardingWizard",
      "VideoCourse"
    ],
    "DeleteClipboardError": [
      "DeleteClipboardNotFoundError",
      "DeleteClipboardUnauthorizedError",
      "DeleteClipboardValidationError"
    ],
    "DeleteNewsAlertError": [
      "DeleteNewsAlertNotFound",
      "DeleteNewsAlertUnauthorizedError",
      "DeleteNewsAlertValidationError"
    ],
    "DeleteSearchProfileError": [
      "DeleteSearchProfileUnauthorizedError",
      "DeleteSearchProfileValidationError"
    ],
    "DeleteSimpleSearchProfileError": [
      "DeleteSimpleSearchProfileDeleteError",
      "DeleteSimpleSearchProfileUnauthorizedError",
      "DeleteSimpleSearchProfileValidationError"
    ],
    "DocumentRemoveResourceDocumentError": [
      "DocumentRemoveResourceDocumentNotFoundError",
      "DocumentRemoveResourceDocumentSaveError",
      "DocumentRemoveResourceDocumentUnauthorizedError"
    ],
    "DocumentSyncCloudinaryUploadError": [
      "DocumentSyncCloudinaryUploadUnauthorizedError",
      "DocumentSyncCloudinaryUploadValidationError"
    ],
    "DocumentUpdateError": [
      "DocumentUpdateNotFoundError",
      "DocumentUpdateSaveError",
      "DocumentUpdateUnauthorizedError"
    ],
    "EditNewsAlertError": [
      "EditNewsAlertNotFound",
      "EditNewsAlertUnauthorizedError",
      "EditNewsAlertValidationError"
    ],
    "EmailSettingsDesignUpdateError": [
      "EmailSettingsDesignUpdateUnauthorizedError",
      "EmailSettingsDesignUpdateValidationError"
    ],
    "EmailSettingsGeneral": [
      "AdvancedEmailSettings",
      "AuthenticatedDomainEmailSettings",
      "EasyCustomEmailSettings",
      "ReplyToEmailSettings"
    ],
    "EmailSettingsGeneralResendEmailError": [
      "EmailSettingsGeneralResendEmailInvalidStateError",
      "EmailSettingsGeneralResendEmailUnauthorizedError"
    ],
    "EmailSettingsGeneralResetError": [
      "EmailSettingsGeneralResetUnauthorizedError"
    ],
    "EmailSettingsGeneralUpdateError": [
      "EmailSettingsGeneralUpdateUnauthorizedError",
      "EmailSettingsGeneralUpdateValidationError"
    ],
    "EmailSettingsValidateAuthenticatedDomainError": [
      "EmailSettingsValidateAuthenticatedDomainInvalidError",
      "EmailSettingsValidateAuthenticatedDomainUnauthorizedError"
    ],
    "EventUpdateError": [
      "EventUpdateNotFoundError",
      "EventUpdateSaveError",
      "EventUpdateUnauthorizedError"
    ],
    "FeatureRequestTrial2Error": [
      "FeatureRequestTrialRequestError",
      "FeatureRequestTrialUnauthorizedError",
      "FeatureRequestTrialUntrialableError"
    ],
    "FindOrCreateShareableClipboardReportLinkError": [
      "FindOrCreateShareableClipboardReportLinkNotFoundError",
      "FindOrCreateShareableClipboardReportLinkUnauthorizedError"
    ],
    "FindOrCreateShareablePrImpactReportLinkError": [
      "FindOrCreateShareablePrImpactReportLinkNotFoundError",
      "FindOrCreateShareablePrImpactReportLinkUnauthorizedError"
    ],
    "HostedNewsroomSettingsUpdateError": [
      "HostedNewsroomSettingsUpdateUnauthorizedError",
      "HostedNewsroomSettingsUpdateValidationError"
    ],
    "HostedNewsroomStylePreviewUpdateError": [
      "HostedNewsroomStylePreviewUpdateUnauthorizedError",
      "HostedNewsroomStylePreviewUpdateValidationError"
    ],
    "HubspotCallbackRequestError": [
      "HubspotCallbackRequestRequestError"
    ],
    "HubspotDemoRequestError": [
      "HubspotDemoRequestRequestError",
      "HubspotDemoRequestValidationError"
    ],
    "ImageSyncCloudinaryUploadError": [
      "ImageSyncCloudinaryUploadUnauthorizedError",
      "ImageSyncCloudinaryUploadValidationError"
    ],
    "ImagesDeleteError": [
      "ImagesDeleteUnauthorizedError"
    ],
    "ImagesImportFromOtherNewsroomError": [
      "ImagesImportFromOtherNewsroomUnauthorizedError"
    ],
    "ImagesUpdateError": [
      "ImagesUpdateNotFoundError",
      "ImagesUpdateUnauthorizedError",
      "ImagesUpdateValidationError"
    ],
    "LinkedinPostCreateError": [
      "LinkedinPostCreateUnauthorizedError",
      "LinkedinPostCreateValidationError"
    ],
    "LinkedinPostDeleteError": [
      "LinkedinPostDeleteAlreadyPublishedError",
      "LinkedinPostDeleteUnauthorizedError"
    ],
    "LinkedinPostEditError": [
      "LinkedinPostEditAlreadyPublishedError",
      "LinkedinPostEditUnauthorizedError",
      "LinkedinPostEditValidationError"
    ],
    "Material": [
      "BlogPost",
      "Document",
      "Event",
      "Image",
      "News",
      "Pressrelease",
      "Video"
    ],
    "MaterialAlertSubscriptionEmailsUpdateError": [
      "MaterialNotFoundError",
      "MaterialPublishedError",
      "SaveError"
    ],
    "MaterialArchiveError": [
      "MaterialArchiveNotFoundError",
      "MaterialArchiveSaveError",
      "MaterialArchiveUnsupportedTypeError"
    ],
    "MaterialChannelMessageUpdateError": [
      "MaterialChannelMessageUpdateNotFoundError",
      "MaterialChannelMessageUpdateUnauthorizedError",
      "MaterialChannelMessageUpdateValidationError"
    ],
    "MaterialChannelsAddError": [
      "MaterialChannelsAddNotFoundError",
      "MaterialChannelsAddUnauthorizedError",
      "MaterialChannelsAddValidationError"
    ],
    "MaterialChannelsRemoveError": [
      "MaterialChannelsRemoveNotFoundError",
      "MaterialChannelsRemoveUnauthorizedError",
      "MaterialChannelsRemoveValidationError"
    ],
    "MaterialChannelsUpdateError": [
      "MaterialChannelsUpdateNotFoundError",
      "MaterialChannelsUpdateUnauthorizedError",
      "MaterialChannelsUpdateValidationError"
    ],
    "MaterialCloneError": [
      "MaterialCloneNotFoundError",
      "MaterialCloneTargetPressroomError",
      "MaterialCloneValidationError"
    ],
    "MaterialConnectError": [
      "MaterialConnectAlreadyConnectedError",
      "MaterialConnectUnauthorizedError"
    ],
    "MaterialContactListsAddError": [
      "MaterialContactListsAddNotFoundError",
      "MaterialContactListsAddValidationError"
    ],
    "MaterialContactListsRemoveError": [
      "MaterialContactListsRemoveNotFoundError",
      "MaterialContactListsRemoveValidationError"
    ],
    "MaterialContactListsUpdateError": [
      "MaterialContactListsUpdateNotFoundError",
      "MaterialContactListsUpdateValidationError"
    ],
    "MaterialDisconnectError": [
      "MaterialDisconnectNotFoundError",
      "MaterialDisconnectResourceImageShouldStayError",
      "MaterialDisconnectUnauthorizedError"
    ],
    "MaterialDuplicateError": [
      "MaterialDuplicateNotFoundError",
      "MaterialDuplicateSaveError"
    ],
    "MaterialFeedbackError": [
      "MaterialFeedbackMaterialNotFoundError",
      "MaterialFeedbackValidationError"
    ],
    "MaterialLinksUpdateError": [
      "MaterialLinksUpdateUnauthorizedError",
      "MaterialLinksUpdateValidationError"
    ],
    "MaterialNetworkContactAddError": [
      "MaterialNetworkContactAddNotFoundError",
      "MaterialNetworkContactAddValidationError"
    ],
    "MaterialNetworkContactRemoveError": [
      "MaterialNetworkContactRemoveNotFoundError",
      "MaterialNetworkContactRemoveValidationError"
    ],
    "MaterialNetworkContactUpdateError": [
      "MaterialNetworkContactUpdateNotFoundError",
      "MaterialNetworkContactUpdateValidationError"
    ],
    "MaterialPublicationTimeUpdateError": [
      "MaterialPublicationTimeUpdateNotFoundError",
      "MaterialPublicationTimeUpdateUnauthorizedError",
      "MaterialPublicationTimeUpdateUpdateError",
      "MaterialPublicationTimeUpdateValidationError"
    ],
    "MaterialPublishError": [
      "MaterialPublishLimitError",
      "MaterialPublishNotFoundError",
      "MaterialPublishPublishedError",
      "MaterialPublishSaveError",
      "MaterialPublishUnauthorizedError"
    ],
    "MaterialRecommendedContactRemoveError": [
      "MaterialRecommendedContactRemoveNotFoundError",
      "MaterialRecommendedContactRemoveValidationError"
    ],
    "MaterialRecommendedContactUpdateError": [
      "MaterialRecommendedContactUpdateNotFoundError",
      "MaterialRecommendedContactUpdateValidationError"
    ],
    "MaterialRegionsAddError": [
      "MaterialRegionsAddNotFoundError",
      "MaterialRegionsAddValidationError"
    ],
    "MaterialRegionsRemoveError": [
      "MaterialRegionsRemoveNotFoundError",
      "MaterialRegionsRemoveValidationError"
    ],
    "MaterialRegionsUpdateError": [
      "MaterialRegionsUpdateNotFoundError",
      "MaterialRegionsUpdateValidationError"
    ],
    "MaterialSubjectsAddError": [
      "MaterialSubjectsAddNotFoundError",
      "MaterialSubjectsAddValidationError"
    ],
    "MaterialSubjectsRemoveError": [
      "MaterialSubjectsRemoveNotFoundError",
      "MaterialSubjectsRemoveValidationError"
    ],
    "MaterialSubjectsUpdateError": [
      "MaterialSubjectsUpdateNotFoundError",
      "MaterialSubjectsUpdateValidationError"
    ],
    "MaterialTagError": [
      "MaterialTagDuplicateTagError",
      "MaterialTagEmptyError",
      "MaterialTagNotFoundError",
      "MaterialTagSaveError",
      "MaterialTaggingAlreadyExistsError"
    ],
    "MaterialTemplateAddError": [
      "MaterialTemplateAddApiError",
      "MaterialTemplateAddFlaggedByModerationError",
      "MaterialTemplateAddPressroomNotFoundError",
      "MaterialTemplateNoOutputError"
    ],
    "MaterialUnscheduleError": [
      "MaterialUnscheduleEmptyError",
      "MaterialUnscheduleNotFoundError",
      "MaterialUnschedulePublishedError",
      "MaterialUnscheduleSaveError"
    ],
    "MaterialUntagError": [
      "MaterialUntagNotFoundError",
      "MaterialUntagNotTaggedError",
      "MaterialUntagSaveError"
    ],
    "MaterialVisibilityUpdateError": [
      "MaterialVisibilityUpdateMaxStickiesError",
      "MaterialVisibilityUpdateNotFoundError"
    ],
    "MaterialsConnectionUpdateError": [
      "MaterialsConnectionUpdateAlreadyConnectedError",
      "MaterialsConnectionUpdateCreateError",
      "MaterialsConnectionUpdateNotFoundError",
      "MaterialsConnectionUpdateUnauthorizedError",
      "MaterialsDisconnectResourceImageShouldStayError"
    ],
    "MndReachItemInterface": [
      "MndReachJournalist",
      "MndReachOutlet"
    ],
    "MonitorSearchProfile": [
      "MonitorBooleanSearchProfile",
      "MonitorProfile"
    ],
    "MoveSearchProfileError": [
      "MoveSearchProfileUnauthorizedError",
      "MoveSearchProfileValidationError"
    ],
    "NetworkContactAddError": [
      "NetworkContactAddUnauthorizedError",
      "NetworkContactAddValidationError",
      "NetworkContactAlreadyExistsError"
    ],
    "NetworkContactExportError": [
      "NetworkContactExportUnauthorizedError"
    ],
    "NetworkContactInviteToFollowBulkError": [
      "NetworkContactInviteToFollowBulkUnauthorizedError",
      "NetworkContactInviteToFollowBulkValidationError"
    ],
    "NetworkContactInviteToFollowError": [
      "NetworkContactInviteToFollowUnauthorizedError",
      "NetworkContactInviteToFollowValidationError"
    ],
    "NetworkContactListAddContactBulkError": [
      "NetworkContactListAddContactBulkNotFoundError",
      "NetworkContactListAddContactBulkUnauthorizedError"
    ],
    "NetworkContactListAddContactError": [
      "NetworkContactListAddContactNotFoundError",
      "NetworkContactListAddContactUnauthorizedError"
    ],
    "NetworkContactListRemoveContactBulkError": [
      "NetworkContactListRemoveContactBulkNotFoundError",
      "NetworkContactListRemoveContactBulkUnauthorizedError"
    ],
    "NetworkContactListRemoveContactError": [
      "NetworkContactListRemoveContactNotFoundError",
      "NetworkContactListRemoveContactUnauthorizedError"
    ],
    "NetworkContactListsAddError": [
      "NetworkContactListsAddValidationError",
      "PressroomNetworkContactListsAddNotFoundError"
    ],
    "NetworkContactListsRemoveError": [
      "NetworkContactListsRemoveNotFoundError",
      "NetworkContactListsRemoveValidationError",
      "PressroomNetworkContactListsRemoveNotFoundError"
    ],
    "NetworkContactListsUpdateError": [
      "NetworkContactListsUpdateNotFoundError",
      "NetworkContactListsUpdateValidationError",
      "PressroomNetworkContactListsUpdateNotFoundError"
    ],
    "NetworkContactMaterialEmailInfoInterface": [
      "NetworkContactMaterialEmailInfo",
      "NetworkContactUnknown"
    ],
    "NetworkContactOrUnknown": [
      "NetworkContact",
      "NetworkContactUnknown"
    ],
    "NetworkContactRemoveBulkError": [
      "NetworkContactRemoveBulkUnauthorizedError"
    ],
    "NetworkContactRemoveError": [
      "NetworkContactRemoveUnauthorizedError"
    ],
    "NetworkContactUpdateError": [
      "NetworkContactUpdateNotFoundError",
      "NetworkContactUpdateUnauthorizedError",
      "NetworkContactUpdateValidationError"
    ],
    "NetworkInactiveContactRemoveError": [
      "NetworkInactiveContactRemoveUnauthorizedError"
    ],
    "NewsUpdateError": [
      "NewsUpdateNotFoundError",
      "NewsUpdateSaveError",
      "NewsUpdateUnauthorizedError"
    ],
    "NewsroomBoilerplateCreateError": [
      "NewsroomBoilerplateCreateUnauthorizedError",
      "NewsroomBoilerplateCreateValidationError"
    ],
    "NewsroomBoilerplateDeleteError": [
      "NewsroomBoilerplateDeleteNotFoundError",
      "NewsroomBoilerplateDeleteUnauthorizedError"
    ],
    "NewsroomBoilerplateUpdateError": [
      "NewsroomBoilerplateUpdateNotFoundError",
      "NewsroomBoilerplateUpdateUnauthorizedError",
      "NewsroomBoilerplateUpdateValidationError"
    ],
    "NewsroomFeed": [
      "FacebookNewsroomFeed",
      "InstagramNewsroomFeed",
      "RssNewsroomFeed",
      "TwitterNewsroomFeed",
      "YoutubeNewsroomFeed"
    ],
    "NewsroomFeedDeleteError": [
      "NewsroomFeedDeleteNotFoundError",
      "NewsroomFeedDeleteUnauthorizedError"
    ],
    "NewsroomFeedFacebookChoosePageError": [
      "NewsroomFeedFacebookChoosePageNotFoundError",
      "NewsroomFeedFacebookChoosePageUnauthorizedError"
    ],
    "NewsroomFeedInputNeeded": [
      "FacebookNewsroomFeedChoosePage",
      "FacebookNewsroomFeedReauthenticate",
      "InstagramNewsroomFeedReauthenticate"
    ],
    "NewsroomFeedRssCreateError": [
      "NewsroomFeedRssCreateUnauthorizedError",
      "NewsroomFeedRssCreateValidationError"
    ],
    "NewsroomFeedTwitterCreateError": [
      "NewsroomFeedTwitterCreateUnauthorizedError",
      "NewsroomFeedTwitterCreateValidationError"
    ],
    "NewsroomFeedYoutubeCreateError": [
      "NewsroomFeedYoutubeCreateUnauthorizedError",
      "NewsroomFeedYoutubeCreateValidationError"
    ],
    "NewsroomReportError": [
      "NewsroomReportNoAccessError"
    ],
    "NewsroomSettingsUpdateError": [
      "NewsroomSettingsUpdateUnauthorizedError",
      "NewsroomSettingsUpdateValidationError"
    ],
    "NewsroomStylePreviewUpdateError": [
      "NewsroomStylePreviewUpdateUnauthorizedError",
      "NewsroomStylePreviewUpdateValidationError"
    ],
    "NewsroomViewsOverTime": [
      "NewsroomViewsOverTimeDate",
      "NewsroomViewsOverTimeInterval"
    ],
    "NpsAnswerError": [
      "NpsAnswerUnauthorizedError",
      "NpsAnswerValidationError"
    ],
    "PasswordChangeError": [
      "PasswordChangeUnauthorizedError",
      "PasswordChangeValidationError"
    ],
    "PasswordCheckError": [
      "PasswordCheckUnauthorizedError"
    ],
    "PasswordForgotError": [
      "PasswordForgotUnauthorizedError"
    ],
    "PitchDeleteError": [
      "PitchDeleteUnauthorizedError"
    ],
    "PitchSendError": [
      "PitchSendAlreadySentError",
      "PitchSendUnauthorizedError"
    ],
    "PitchState2": [
      "PitchClickedState",
      "PitchDraftState",
      "PitchMediaHitState",
      "PitchOpenedState",
      "PitchSentState",
      "PitchUnreachableState",
      "PitchUnsubscribed"
    ],
    "PitchTo": [
      "MndReachJournalist",
      "NetworkContact"
    ],
    "PitchUpdateError": [
      "PitchUpdateNotFoundError",
      "PitchUpdateSaveError",
      "PitchUpdateUnauthorizedError",
      "PitchUpdateValidationError"
    ],
    "PlanPrice": [
      "ContactUsPrice",
      "SelfServePrice"
    ],
    "PossibleHit": [
      "MonitorHit",
      "RestrictedMonitorHit"
    ],
    "PossibleQuotation": [
      "Quotation",
      "RestrictedQuotation"
    ],
    "PrImpactCreateHitError": [
      "PrImpactCreateHitNotFoundError",
      "PrImpactCreateHitValidationError"
    ],
    "PressReleaseUpdateError": [
      "PressReleaseUpdateNotFoundError",
      "PressReleaseUpdateSaveError",
      "PressReleaseUpdateUnauthorizedError"
    ],
    "PressroomGoLiveError": [
      "PressroomGoLiveNotFoundError",
      "PressroomGoLiveUnauthorizedError"
    ],
    "QuotationDeleteError": [
      "QuotationAuthorizationError",
      "QuotationNotFoundError"
    ],
    "QuotationSettingsError": [
      "QuotationSettingsMaterialNotFoundError",
      "QuotationSettingsValidationError"
    ],
    "RecipientDomainOrUnknown": [
      "RecipientDomain",
      "RecipientDomainUnknown"
    ],
    "RecommendedContactsAddToMaterialError": [
      "RecommendedContactsAddToMaterialUnauthorizedError",
      "RecommendedContactsAddToMaterialValidationError"
    ],
    "RegenerateSearchProfileError": [
      "RegenerateSearchProfileNotFoundError",
      "RegenerateSearchProfileUnauthorizedError",
      "RegenerateSearchProfileValidationError"
    ],
    "RemoveHitFromClipboardError": [
      "RemoveHitFromClipboardUnauthorizedError",
      "RemoveHitFromClipboardValidationError"
    ],
    "RemoveHitsError": [
      "RemoveHitsNotFoundError",
      "RemoveHitsUnauthorizedError"
    ],
    "ReportAddExternalCoverageError": [
      "ReportAddExternalCoverageFailedError",
      "ReportAddExternalCoverageNetworkError",
      "ReportAddExternalCoverageUnauthorizedError",
      "ReportAddExternalCoverageValidationError",
      "ReportAddExternalNoSubscriptionError"
    ],
    "ReportAddMonitorCoverageError": [
      "ReportAddMonitorCoverageFailedError",
      "ReportAddMonitorCoverageUnauthorizedError",
      "ReportAddMonitorCoverageValidationError",
      "ReportAddMonitorNoSubscriptionError"
    ],
    "ReportAddSectionError": [
      "ReportAddSectionNoSubscriptionError",
      "ReportAddSectionUnauthorizedError"
    ],
    "ReportAllSectionsInterface": [
      "ReportFeaturedSection",
      "ReportMenuSection",
      "ReportMetricsSection",
      "ReportPerceptionBenchmarkSection",
      "ReportPerceptionBenchmarkSentimentOverview",
      "ReportPerceptionSection",
      "ReportPerceptionSentimentOverTime",
      "ReportPerceptionSentimentOverview",
      "ReportQuotationFullWidthSection",
      "ReportQuotationGridSection",
      "ReportSectionInsights",
      "ReportSummaryCover",
      "ReportSummaryKeyMetrics",
      "ReportSummarySection",
      "ReportVisibilityBenchmarkSection",
      "ReportVisibilityBenchmarkShareOfVoice",
      "ReportVisibilityBenchmarkShareOfVoiceOverTime",
      "ReportVisibilityHighlights",
      "ReportVisibilityMentionsOverTime",
      "ReportVisibilityOverview",
      "ReportVisibilityReachOverTime",
      "ReportVisibilitySection"
    ],
    "ReportCancelImportJobError": [
      "ReportCancelImportJobUnauthorizedError"
    ],
    "ReportCoverageInterface": [
      "ReportExternalQuotation",
      "ReportMonitorQuotation",
      "ReportQuotation"
    ],
    "ReportCreateError": [
      "ReportCreateNoSubscriptionError",
      "ReportCreateUnauthorizedError",
      "ReportCreateUnknownResourceError",
      "ReportCreateValidationError"
    ],
    "ReportInterface": [
      "BrandReport",
      "CoverageReport"
    ],
    "ReportPerceptionBenchmarkSubSectionInterface": [
      "ReportPerceptionBenchmarkSentimentOverview",
      "ReportSectionInsights"
    ],
    "ReportPerceptionSubSectionInterface": [
      "ReportPerceptionSentimentOverTime",
      "ReportPerceptionSentimentOverview",
      "ReportSectionInsights"
    ],
    "ReportPopulateFeaturedError": [
      "ReportPopulateFeaturedFailedError",
      "ReportPopulateFeaturedNoSubscriptionError",
      "ReportPopulateFeaturedUnauthorizedError",
      "ReportPopulateFeaturedValidationError"
    ],
    "ReportRemoveCoverageError": [
      "ReportRemoveCoverageFailedError",
      "ReportRemoveCoverageNoSubscriptionError",
      "ReportRemoveCoverageUnauthorizedError"
    ],
    "ReportRemoveError": [
      "ReportRemoveUnauthorizedError"
    ],
    "ReportRemoveSectionError": [
      "ReportRemoveSectionNoSubscriptionError",
      "ReportRemoveSectionUnauthorizedError"
    ],
    "ReportRequestPdfSlideshowError": [
      "ReportRequestPdfSlideshowNoSubscriptionError",
      "ReportRequestPdfSlideshowUnauthorizedError"
    ],
    "ReportSectionInterface": [
      "ReportFeaturedSection",
      "ReportMenuSection",
      "ReportMetricsSection",
      "ReportPerceptionBenchmarkSection",
      "ReportPerceptionSection",
      "ReportQuotationFullWidthSection",
      "ReportQuotationGridSection",
      "ReportSummarySection",
      "ReportVisibilityBenchmarkSection",
      "ReportVisibilitySection"
    ],
    "ReportSetCoverageFeaturedError": [
      "ReportSetCoverageFeaturedFailedError",
      "ReportSetCoverageFeaturedNoSubscriptionError",
      "ReportSetCoverageFeaturedUnauthorizedError"
    ],
    "ReportSetCoverageHiddenError": [
      "ReportSetCoverageHiddenFailedError",
      "ReportSetCoverageHiddenNoSubscriptionError",
      "ReportSetCoverageHiddenUnauthorizedError"
    ],
    "ReportSetCoverageSentimentError": [
      "ReportSetCoverageSentimentFailedError",
      "ReportSetCoverageSentimentNoSubscriptionError",
      "ReportSetCoverageSentimentUnauthorizedError"
    ],
    "ReportSortCoverageError": [
      "ReportSortCoverageFailedError",
      "ReportSortCoverageNoSubscriptionError",
      "ReportSortCoverageUnauthorizedError"
    ],
    "ReportSortSectionError": [
      "ReportSortSectionFailedError",
      "ReportSortSectionNoSubscriptionError",
      "ReportSortSectionUnauthorizedError"
    ],
    "ReportSummarySubSectionInterface": [
      "ReportSectionInsights",
      "ReportSummaryCover",
      "ReportSummaryKeyMetrics"
    ],
    "ReportUpdateCoverageError": [
      "ReportUpdateCoverageFailedError",
      "ReportUpdateCoverageNoSubscriptionError",
      "ReportUpdateCoverageUnauthorizedError"
    ],
    "ReportUpdateError": [
      "ReportUpdateNoSubscriptionError",
      "ReportUpdateUnauthorizedError",
      "ReportUpdateUnknownResourceError",
      "ReportUpdateValidationError"
    ],
    "ReportUpdateInsightError": [
      "ReportUpdateInsightNoSubscriptionError",
      "ReportUpdateInsightUnauthorizedError",
      "ReportUpdateInsightValidationError"
    ],
    "ReportUpdatePrValueSettingsError": [
      "ReportUpdatePrValueSettingsFailedError",
      "ReportUpdatePrValueSettingsNoSubscriptionError",
      "ReportUpdatePrValueSettingsUnauthorizedError"
    ],
    "ReportUpdateSectionError": [
      "ReportUpdateSectionNoSubscriptionError",
      "ReportUpdateSectionUnauthorizedError",
      "ReportUpdateSectionValidationError"
    ],
    "ReportVisibilityBenchmarkSubSectionInterface": [
      "ReportSectionInsights",
      "ReportVisibilityBenchmarkShareOfVoice",
      "ReportVisibilityBenchmarkShareOfVoiceOverTime"
    ],
    "ReportVisibilitySubSectionInterface": [
      "ReportSectionInsights",
      "ReportVisibilityHighlights",
      "ReportVisibilityMentionsOverTime",
      "ReportVisibilityOverview",
      "ReportVisibilityReachOverTime"
    ],
    "ReportsGenerateSectionDescriptionPromptDataError": [
      "ReportsGenerateSectionDescriptionPromptDataEmptySectionError",
      "ReportsGenerateSectionDescriptionPromptDataNoSubscriptionError",
      "ReportsGenerateSectionDescriptionPromptDataUnauthorizedError"
    ],
    "RequestDemoError": [
      "RequestDemoRequestError",
      "RequestDemoUnauthorizedError",
      "RequestDemoValidationError"
    ],
    "ResourceImageUpdateError": [
      "ResourceImageUpdateNotFoundError",
      "ResourceImageUpdateSaveError",
      "ResourceImageUpdateUnauthorizedError"
    ],
    "SearchError": [
      "SearchTimeoutError"
    ],
    "SendClipboardEmailError": [
      "SendClipboardEmailNotFoundError",
      "SendClipboardEmailUnauthorizedError"
    ],
    "SendExclusiveError": [
      "SendExclusiveNotLiveError",
      "SendExclusiveUnauthorizedError",
      "SendExclusiveValidationError"
    ],
    "SocialChannel": [
      "FacebookChannel",
      "LinkedinChannel",
      "TwitterChannel",
      "YoutubeChannel"
    ],
    "SourceToggle2faError": [
      "SourceToggle2faUnauthorizedError"
    ],
    "SourceToggleFollowerNotificationsError": [
      "SourceToggleFollowerNotificationsUnauthorizedError"
    ],
    "StoryIdeaLikeError": [
      "StoryIdeaLikeNotFoundError",
      "StoryIdeaLikeUnauthorizedError"
    ],
    "StoryMaterial": [
      "BlogPost",
      "News",
      "Pressrelease"
    ],
    "StoryWidgetUpdateError": [
      "StoryWidgetUpdateUnauthorizedError",
      "StoryWidgetUpdateValidationError"
    ],
    "TagCombineError": [
      "TagCombineNotFoundError",
      "TagCombineUnauthorizedError",
      "TagCombineValidationError"
    ],
    "TagDeleteError": [
      "TagDeleteUnauthorizedError"
    ],
    "TagMoveHighlightedError": [
      "TagMoveHighlightedNotFoundError",
      "TagMoveHighlightedUnauthorizedError"
    ],
    "TagUntagMaterialsError": [
      "TagUntagMaterialsNotFoundError",
      "TagUntagMaterialsUnauthorizedError"
    ],
    "TagUpdateError": [
      "TagUpdateNotFoundError",
      "TagUpdateUnauthorizedError",
      "TagUpdateValidationError"
    ],
    "TwoFactorDisableError": [
      "TwoFactorDisableUnauthorizedError",
      "TwoFactorDisableValidationError"
    ],
    "TwoFactorEnableError": [
      "TwoFactorEnableAlreadyActiveError",
      "TwoFactorEnableUnauthorizedError",
      "TwoFactorEnableValidationError"
    ],
    "TwoFactorRenewRecoveryCodesError": [
      "TwoFactorRenewRecoveryCodesUnauthorizedError",
      "TwoFactorRenewRecoveryCodesValidationError"
    ],
    "UpdateClipboardError": [
      "UpdateClipboardNotFoundError",
      "UpdateClipboardUnauthorizedError",
      "UpdateClipboardValidationError"
    ],
    "UpdateClipboardHitsError": [
      "UpdateClipboardHitsNotFoundError",
      "UpdateClipboardHitsUnauthorizedError",
      "UpdateClipboardHitsValidationError"
    ],
    "UpdateHit2Error": [
      "UpdateHit2FailedError",
      "UpdateHit2NotFoundError",
      "UpdateHit2UnauthorizedError"
    ],
    "UpdateHitError": [
      "UpdateHitFailedError",
      "UpdateHitNotFoundError",
      "UpdateHitUnauthorizedError"
    ],
    "UpdateSearchProfileError": [
      "UpdateSearchProfileUnauthorizedError",
      "UpdateSearchProfileValidationError"
    ],
    "UserAccessDeleteError": [
      "UserAccessDeleteAccountOwnerError",
      "UserAccessDeleteNotFoundError",
      "UserAccessDeleteUnauthorizedError"
    ],
    "UserActivationEmailResendError": [
      "UserActivationEmailResendActivatedError",
      "UserActivationEmailResendAuthError"
    ],
    "UserActivationEmailUpdateError": [
      "UserActivationEmailUpdateAuthError",
      "UserActivationEmailUpdateValidationError"
    ],
    "UserInvitationCreateError": [
      "UserInvitationCreateUnauthorizedAdminError",
      "UserInvitationCreateUnauthorizedError",
      "UserInvitationCreateValidationError"
    ],
    "UserInvitationDeleteError": [
      "UserInvitationDeleteUnauthorizedError"
    ],
    "UserMaterialEmailInfoInterface": [
      "UserMaterialEmailInfo",
      "UserUnknown"
    ],
    "UserOrUnknown": [
      "User",
      "UserUnknown"
    ],
    "UserPromoteToOwnerError": [
      "UserPromoteToOwnerNotFoundError",
      "UserPromoteToOwnerUnauthorizedError"
    ],
    "UserRemoveSelfError": [
      "UserRemoveSelfUnauthorizedError",
      "UserRemoveSelfValidationError"
    ],
    "UserUpdateError": [
      "UserUpdateUnauthorizedError",
      "UserUpdateValidationError"
    ],
    "VideoCreateScreen9UploadLinkError": [
      "VideoCreateScreen9UploadLinkUnauthorizedError"
    ],
    "VideoProgressUpdateError": [
      "VideoProgressUpdateValidationError"
    ],
    "VideoRemoveResourceVideoError": [
      "VideoRemoveResourceVideoNotFoundError",
      "VideoRemoveResourceVideoSaveError",
      "VideoRemoveResourceVideoUnauthorizedError"
    ],
    "VideoSyncScreen9UploadError": [
      "VideoSyncScreen9UploadNotFoundError",
      "VideoSyncScreen9UploadUnauthorizedError",
      "VideoSyncScreen9UploadValidationError"
    ],
    "VideoUpdateError": [
      "VideoUpdateNotFoundError",
      "VideoUpdateSaveError",
      "VideoUpdateUnauthorizedError"
    ],
    "VideoUpdateThumbnailError": [
      "VideoUpdateThumbnailInvalidArgumentError",
      "VideoUpdateThumbnailUnauthorizedError"
    ],
    "WithImageUnion": [
      "BlogPost",
      "BrandReport",
      "ContactPerson2",
      "CoverageReport",
      "Document",
      "Event",
      "LinkedinPost",
      "News",
      "Pressrelease",
      "Tag",
      "Video"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ActivateSimpleSearchProfilesError": [
      "ActivateSimpleSearchProfilesOnboardingCompletedError",
      "ActivateSimpleSearchProfilesUnauthorizedError",
      "ActivateSimpleSearchProfilesValidationError"
    ],
    "AddContactListError": [
      "AddContactListNetworkLabelNotFoundError",
      "AddContactListUnauthorizedError",
      "AddContactListValidationError"
    ],
    "AddHitToClipboardError": [
      "AddHitToClipboardUnauthorizedError",
      "AddHitToClipboardValidationError"
    ],
    "AnalyzeCreateExportError": [
      "AnalyzeCreateExportFailedError",
      "AnalyzeCreateExportInvalidEmailError",
      "AnalyzeCreateExportPressroomNotFoundError"
    ],
    "AudienceBuilderAdvancedSearchCreateError": [
      "AudienceBuilderAdvancedSearchCreateUnauthorizedError",
      "AudienceBuilderAdvancedSearchCreateValidationError"
    ],
    "AudienceBuilderAdvancedSearchDeleteError": [
      "AudienceBuilderAdvancedSearchDeleteNotFoundError",
      "AudienceBuilderAdvancedSearchDeleteUnauthorizedError"
    ],
    "AudienceBuilderSearchError": [
      "AgilityPrValidationError",
      "ApiServerError",
      "TimeoutError",
      "UnauthorizedError"
    ],
    "BlogPostUpdateError": [
      "BlogPostUpdateNotFoundError",
      "BlogPostUpdateSaveError",
      "BlogPostUpdateUnauthorizedError"
    ],
    "BulkDownloadError": [
      "BulkDownloadSaveError"
    ],
    "Channel": [
      "FacebookChannel",
      "LinkedinChannel",
      "PartnerChannel",
      "TwitterChannel",
      "YoutubeChannel"
    ],
    "ChannelAddError": [
      "ChannelAddNotFoundError",
      "ChannelAddUnauthorizedError",
      "ChannelAddUserAlreadyAddedError"
    ],
    "ChannelDisconnectError": [
      "ChannelDisconnectNotFoundError",
      "ChannelDisconnectUnauthorizedError"
    ],
    "ChannelReauthenticateError": [
      "ChannelReauthenticateNotFoundError",
      "ChannelReauthenticateUnauthorizedError"
    ],
    "ChannelUpdateError": [
      "ChannelUpdateUnauthorizedError",
      "ChannelUpdateValidationError"
    ],
    "CommercialProductPurchaseError": [
      "CommercialProductPurchaseRequestError",
      "CommercialProductPurchaseUnauthorizedError"
    ],
    "CommercialProductTrialError": [
      "CommercialProductTrialRequestError",
      "CommercialProductTrialUnauthorizedError"
    ],
    "ContactImport": [
      "AudienceTargeting",
      "ContactImportAgilityPR",
      "ContactImportAudienceList",
      "ContactImportFileUpload",
      "ContactImportPaste",
      "FindContact",
      "MediaRelations",
      "SuggestedContactsImport"
    ],
    "ContactImportMutationError": [
      "ContactListImportError",
      "ImportLimitReachedError",
      "KeywordOrParametricSearchImportError",
      "QueryImportError"
    ],
    "ContactListSaveError": [
      "ContactListSaveUnauthorizedError",
      "ContactListSaveValidationError"
    ],
    "ContactPersonConnectError": [
      "ContactPersonConnectAlreadyConnectedError",
      "ContactPersonConnectNotFoundError",
      "ContactPersonConnectUnauthorizedError"
    ],
    "ContactPersonDeleteError": [
      "ContactPersonDeleteUnauthorizedError"
    ],
    "ContactPersonDisconnectError": [
      "ContactPersonDisconnectNotFoundError",
      "ContactPersonDisconnectUnauthorizedError"
    ],
    "ContactPersonMoveError": [
      "ContactPersonMoveUnauthorizedError",
      "ContactPersonMoveValidationError"
    ],
    "ContactPersonRemoveImageError": [
      "ContactPersonRemoveImageNotFoundError",
      "ContactPersonRemoveImageSaveError",
      "ContactPersonRemoveImageUnauthorizedError"
    ],
    "ContactPersonUpdateError": [
      "ContactPersonUpdateNotFoundError",
      "ContactPersonUpdateUnauthorizedError",
      "ContactPersonUpdateValidationError"
    ],
    "ContactsFindJournalistImportError": [
      "ContactsFindJournalistImportNetworkLabelNotFoundError",
      "ContactsFindJournalistImportUnauthorizedError",
      "FindJournalistImportLimitReachedError"
    ],
    "ContactsImportAudienceListError": [
      "ContactsImportAudienceListNotFoundError",
      "ContactsImportAudienceListUnauthorizedError"
    ],
    "ContactsImportEmailListError": [
      "ContactsImportEmailListUnauthorizedError",
      "ContactsImportEmailListValidationError"
    ],
    "ContactsImportFileError": [
      "ContactsImportFileUnauthorizedError",
      "ContactsImportFileValidationError"
    ],
    "ContactsMediaRelationsImportError": [
      "ContactsMediaRelationsImportNetworkLabelNotFoundError",
      "ContactsMediaRelationsImportUnauthorizedError"
    ],
    "CopilotUrlSaveError": [
      "CopilotUrlSaveUnauthorizedError",
      "CopilotUrlSaveValidationError"
    ],
    "CopilotUrlUpdateError": [
      "CopilotUrlUpdateToFrequentError",
      "CopilotUrlUpdateUnauthorizedError"
    ],
    "CreateClipboardError": [
      "CreateClipboardUnauthorizedError",
      "CreateClipboardValidationError"
    ],
    "CreateNewsAlertError": [
      "CreateNewsAlertUnauthorizedError",
      "CreateNewsAlertValidationError"
    ],
    "CreateOrUpdateSearchGroupError": [
      "CreateOrUpdateSearchGroupUnauthorizedError",
      "CreateOrUpdateSearchGroupValidationError"
    ],
    "CreateSearchProfileError": [
      "CreateSearchProfileLimitExceededError",
      "CreateSearchProfileUnauthorizedError",
      "CreateSearchProfileValidationError"
    ],
    "CreateSimpleSearchProfileError": [
      "CreateSimpleSearchProfileUnauthorizedError",
      "CreateSimpleSearchProfileValidationError"
    ],
    "DashboardItem": [
      "AccountVerificationPending",
      "BrandReportBannerH12024",
      "BrandReportBought",
      "BrandReportNeverTried",
      "BrandReportTrial",
      "CoPilot",
      "GeneratedBrandReport",
      "LatestFollowers",
      "LatestMediaCoverage",
      "LatestMediaCoverageUnlocker",
      "LatestStory",
      "MostPopularMaterials",
      "NewsroomStatistics",
      "OnboardingWizard",
      "VideoCourse"
    ],
    "DeleteClipboardError": [
      "DeleteClipboardNotFoundError",
      "DeleteClipboardUnauthorizedError",
      "DeleteClipboardValidationError"
    ],
    "DeleteNewsAlertError": [
      "DeleteNewsAlertNotFound",
      "DeleteNewsAlertUnauthorizedError",
      "DeleteNewsAlertValidationError"
    ],
    "DeleteSearchProfileError": [
      "DeleteSearchProfileUnauthorizedError",
      "DeleteSearchProfileValidationError"
    ],
    "DeleteSimpleSearchProfileError": [
      "DeleteSimpleSearchProfileDeleteError",
      "DeleteSimpleSearchProfileUnauthorizedError",
      "DeleteSimpleSearchProfileValidationError"
    ],
    "DocumentRemoveResourceDocumentError": [
      "DocumentRemoveResourceDocumentNotFoundError",
      "DocumentRemoveResourceDocumentSaveError",
      "DocumentRemoveResourceDocumentUnauthorizedError"
    ],
    "DocumentSyncCloudinaryUploadError": [
      "DocumentSyncCloudinaryUploadUnauthorizedError",
      "DocumentSyncCloudinaryUploadValidationError"
    ],
    "DocumentUpdateError": [
      "DocumentUpdateNotFoundError",
      "DocumentUpdateSaveError",
      "DocumentUpdateUnauthorizedError"
    ],
    "EditNewsAlertError": [
      "EditNewsAlertNotFound",
      "EditNewsAlertUnauthorizedError",
      "EditNewsAlertValidationError"
    ],
    "EmailSettingsDesignUpdateError": [
      "EmailSettingsDesignUpdateUnauthorizedError",
      "EmailSettingsDesignUpdateValidationError"
    ],
    "EmailSettingsGeneral": [
      "AdvancedEmailSettings",
      "AuthenticatedDomainEmailSettings",
      "EasyCustomEmailSettings",
      "ReplyToEmailSettings"
    ],
    "EmailSettingsGeneralResendEmailError": [
      "EmailSettingsGeneralResendEmailInvalidStateError",
      "EmailSettingsGeneralResendEmailUnauthorizedError"
    ],
    "EmailSettingsGeneralResetError": [
      "EmailSettingsGeneralResetUnauthorizedError"
    ],
    "EmailSettingsGeneralUpdateError": [
      "EmailSettingsGeneralUpdateUnauthorizedError",
      "EmailSettingsGeneralUpdateValidationError"
    ],
    "EmailSettingsValidateAuthenticatedDomainError": [
      "EmailSettingsValidateAuthenticatedDomainInvalidError",
      "EmailSettingsValidateAuthenticatedDomainUnauthorizedError"
    ],
    "EventUpdateError": [
      "EventUpdateNotFoundError",
      "EventUpdateSaveError",
      "EventUpdateUnauthorizedError"
    ],
    "FeatureRequestTrial2Error": [
      "FeatureRequestTrialRequestError",
      "FeatureRequestTrialUnauthorizedError",
      "FeatureRequestTrialUntrialableError"
    ],
    "FindOrCreateShareableClipboardReportLinkError": [
      "FindOrCreateShareableClipboardReportLinkNotFoundError",
      "FindOrCreateShareableClipboardReportLinkUnauthorizedError"
    ],
    "FindOrCreateShareablePrImpactReportLinkError": [
      "FindOrCreateShareablePrImpactReportLinkNotFoundError",
      "FindOrCreateShareablePrImpactReportLinkUnauthorizedError"
    ],
    "HostedNewsroomSettingsUpdateError": [
      "HostedNewsroomSettingsUpdateUnauthorizedError",
      "HostedNewsroomSettingsUpdateValidationError"
    ],
    "HostedNewsroomStylePreviewUpdateError": [
      "HostedNewsroomStylePreviewUpdateUnauthorizedError",
      "HostedNewsroomStylePreviewUpdateValidationError"
    ],
    "HubspotCallbackRequestError": [
      "HubspotCallbackRequestRequestError"
    ],
    "HubspotDemoRequestError": [
      "HubspotDemoRequestRequestError",
      "HubspotDemoRequestValidationError"
    ],
    "ImageSyncCloudinaryUploadError": [
      "ImageSyncCloudinaryUploadUnauthorizedError",
      "ImageSyncCloudinaryUploadValidationError"
    ],
    "ImagesDeleteError": [
      "ImagesDeleteUnauthorizedError"
    ],
    "ImagesImportFromOtherNewsroomError": [
      "ImagesImportFromOtherNewsroomUnauthorizedError"
    ],
    "ImagesUpdateError": [
      "ImagesUpdateNotFoundError",
      "ImagesUpdateUnauthorizedError",
      "ImagesUpdateValidationError"
    ],
    "LinkedinPostCreateError": [
      "LinkedinPostCreateUnauthorizedError",
      "LinkedinPostCreateValidationError"
    ],
    "LinkedinPostDeleteError": [
      "LinkedinPostDeleteAlreadyPublishedError",
      "LinkedinPostDeleteUnauthorizedError"
    ],
    "LinkedinPostEditError": [
      "LinkedinPostEditAlreadyPublishedError",
      "LinkedinPostEditUnauthorizedError",
      "LinkedinPostEditValidationError"
    ],
    "Material": [
      "BlogPost",
      "Document",
      "Event",
      "Image",
      "News",
      "Pressrelease",
      "Video"
    ],
    "MaterialAlertSubscriptionEmailsUpdateError": [
      "MaterialNotFoundError",
      "MaterialPublishedError",
      "SaveError"
    ],
    "MaterialArchiveError": [
      "MaterialArchiveNotFoundError",
      "MaterialArchiveSaveError",
      "MaterialArchiveUnsupportedTypeError"
    ],
    "MaterialChannelMessageUpdateError": [
      "MaterialChannelMessageUpdateNotFoundError",
      "MaterialChannelMessageUpdateUnauthorizedError",
      "MaterialChannelMessageUpdateValidationError"
    ],
    "MaterialChannelsAddError": [
      "MaterialChannelsAddNotFoundError",
      "MaterialChannelsAddUnauthorizedError",
      "MaterialChannelsAddValidationError"
    ],
    "MaterialChannelsRemoveError": [
      "MaterialChannelsRemoveNotFoundError",
      "MaterialChannelsRemoveUnauthorizedError",
      "MaterialChannelsRemoveValidationError"
    ],
    "MaterialChannelsUpdateError": [
      "MaterialChannelsUpdateNotFoundError",
      "MaterialChannelsUpdateUnauthorizedError",
      "MaterialChannelsUpdateValidationError"
    ],
    "MaterialCloneError": [
      "MaterialCloneNotFoundError",
      "MaterialCloneTargetPressroomError",
      "MaterialCloneValidationError"
    ],
    "MaterialConnectError": [
      "MaterialConnectAlreadyConnectedError",
      "MaterialConnectUnauthorizedError"
    ],
    "MaterialContactListsAddError": [
      "MaterialContactListsAddNotFoundError",
      "MaterialContactListsAddValidationError"
    ],
    "MaterialContactListsRemoveError": [
      "MaterialContactListsRemoveNotFoundError",
      "MaterialContactListsRemoveValidationError"
    ],
    "MaterialContactListsUpdateError": [
      "MaterialContactListsUpdateNotFoundError",
      "MaterialContactListsUpdateValidationError"
    ],
    "MaterialDisconnectError": [
      "MaterialDisconnectNotFoundError",
      "MaterialDisconnectResourceImageShouldStayError",
      "MaterialDisconnectUnauthorizedError"
    ],
    "MaterialDuplicateError": [
      "MaterialDuplicateNotFoundError",
      "MaterialDuplicateSaveError"
    ],
    "MaterialFeedbackError": [
      "MaterialFeedbackMaterialNotFoundError",
      "MaterialFeedbackValidationError"
    ],
    "MaterialLinksUpdateError": [
      "MaterialLinksUpdateUnauthorizedError",
      "MaterialLinksUpdateValidationError"
    ],
    "MaterialNetworkContactAddError": [
      "MaterialNetworkContactAddNotFoundError",
      "MaterialNetworkContactAddValidationError"
    ],
    "MaterialNetworkContactRemoveError": [
      "MaterialNetworkContactRemoveNotFoundError",
      "MaterialNetworkContactRemoveValidationError"
    ],
    "MaterialNetworkContactUpdateError": [
      "MaterialNetworkContactUpdateNotFoundError",
      "MaterialNetworkContactUpdateValidationError"
    ],
    "MaterialPublicationTimeUpdateError": [
      "MaterialPublicationTimeUpdateNotFoundError",
      "MaterialPublicationTimeUpdateUnauthorizedError",
      "MaterialPublicationTimeUpdateUpdateError",
      "MaterialPublicationTimeUpdateValidationError"
    ],
    "MaterialPublishError": [
      "MaterialPublishLimitError",
      "MaterialPublishNotFoundError",
      "MaterialPublishPublishedError",
      "MaterialPublishSaveError",
      "MaterialPublishUnauthorizedError"
    ],
    "MaterialRecommendedContactRemoveError": [
      "MaterialRecommendedContactRemoveNotFoundError",
      "MaterialRecommendedContactRemoveValidationError"
    ],
    "MaterialRecommendedContactUpdateError": [
      "MaterialRecommendedContactUpdateNotFoundError",
      "MaterialRecommendedContactUpdateValidationError"
    ],
    "MaterialRegionsAddError": [
      "MaterialRegionsAddNotFoundError",
      "MaterialRegionsAddValidationError"
    ],
    "MaterialRegionsRemoveError": [
      "MaterialRegionsRemoveNotFoundError",
      "MaterialRegionsRemoveValidationError"
    ],
    "MaterialRegionsUpdateError": [
      "MaterialRegionsUpdateNotFoundError",
      "MaterialRegionsUpdateValidationError"
    ],
    "MaterialSubjectsAddError": [
      "MaterialSubjectsAddNotFoundError",
      "MaterialSubjectsAddValidationError"
    ],
    "MaterialSubjectsRemoveError": [
      "MaterialSubjectsRemoveNotFoundError",
      "MaterialSubjectsRemoveValidationError"
    ],
    "MaterialSubjectsUpdateError": [
      "MaterialSubjectsUpdateNotFoundError",
      "MaterialSubjectsUpdateValidationError"
    ],
    "MaterialTagError": [
      "MaterialTagDuplicateTagError",
      "MaterialTagEmptyError",
      "MaterialTagNotFoundError",
      "MaterialTagSaveError",
      "MaterialTaggingAlreadyExistsError"
    ],
    "MaterialTemplateAddError": [
      "MaterialTemplateAddApiError",
      "MaterialTemplateAddFlaggedByModerationError",
      "MaterialTemplateAddPressroomNotFoundError",
      "MaterialTemplateNoOutputError"
    ],
    "MaterialUnscheduleError": [
      "MaterialUnscheduleEmptyError",
      "MaterialUnscheduleNotFoundError",
      "MaterialUnschedulePublishedError",
      "MaterialUnscheduleSaveError"
    ],
    "MaterialUntagError": [
      "MaterialUntagNotFoundError",
      "MaterialUntagNotTaggedError",
      "MaterialUntagSaveError"
    ],
    "MaterialVisibilityUpdateError": [
      "MaterialVisibilityUpdateMaxStickiesError",
      "MaterialVisibilityUpdateNotFoundError"
    ],
    "MaterialsConnectionUpdateError": [
      "MaterialsConnectionUpdateAlreadyConnectedError",
      "MaterialsConnectionUpdateCreateError",
      "MaterialsConnectionUpdateNotFoundError",
      "MaterialsConnectionUpdateUnauthorizedError",
      "MaterialsDisconnectResourceImageShouldStayError"
    ],
    "MndReachItemInterface": [
      "MndReachJournalist",
      "MndReachOutlet"
    ],
    "MonitorSearchProfile": [
      "MonitorBooleanSearchProfile",
      "MonitorProfile"
    ],
    "MoveSearchProfileError": [
      "MoveSearchProfileUnauthorizedError",
      "MoveSearchProfileValidationError"
    ],
    "NetworkContactAddError": [
      "NetworkContactAddUnauthorizedError",
      "NetworkContactAddValidationError",
      "NetworkContactAlreadyExistsError"
    ],
    "NetworkContactExportError": [
      "NetworkContactExportUnauthorizedError"
    ],
    "NetworkContactInviteToFollowBulkError": [
      "NetworkContactInviteToFollowBulkUnauthorizedError",
      "NetworkContactInviteToFollowBulkValidationError"
    ],
    "NetworkContactInviteToFollowError": [
      "NetworkContactInviteToFollowUnauthorizedError",
      "NetworkContactInviteToFollowValidationError"
    ],
    "NetworkContactListAddContactBulkError": [
      "NetworkContactListAddContactBulkNotFoundError",
      "NetworkContactListAddContactBulkUnauthorizedError"
    ],
    "NetworkContactListAddContactError": [
      "NetworkContactListAddContactNotFoundError",
      "NetworkContactListAddContactUnauthorizedError"
    ],
    "NetworkContactListRemoveContactBulkError": [
      "NetworkContactListRemoveContactBulkNotFoundError",
      "NetworkContactListRemoveContactBulkUnauthorizedError"
    ],
    "NetworkContactListRemoveContactError": [
      "NetworkContactListRemoveContactNotFoundError",
      "NetworkContactListRemoveContactUnauthorizedError"
    ],
    "NetworkContactListsAddError": [
      "NetworkContactListsAddValidationError",
      "PressroomNetworkContactListsAddNotFoundError"
    ],
    "NetworkContactListsRemoveError": [
      "NetworkContactListsRemoveNotFoundError",
      "NetworkContactListsRemoveValidationError",
      "PressroomNetworkContactListsRemoveNotFoundError"
    ],
    "NetworkContactListsUpdateError": [
      "NetworkContactListsUpdateNotFoundError",
      "NetworkContactListsUpdateValidationError",
      "PressroomNetworkContactListsUpdateNotFoundError"
    ],
    "NetworkContactMaterialEmailInfoInterface": [
      "NetworkContactMaterialEmailInfo",
      "NetworkContactUnknown"
    ],
    "NetworkContactOrUnknown": [
      "NetworkContact",
      "NetworkContactUnknown"
    ],
    "NetworkContactRemoveBulkError": [
      "NetworkContactRemoveBulkUnauthorizedError"
    ],
    "NetworkContactRemoveError": [
      "NetworkContactRemoveUnauthorizedError"
    ],
    "NetworkContactUpdateError": [
      "NetworkContactUpdateNotFoundError",
      "NetworkContactUpdateUnauthorizedError",
      "NetworkContactUpdateValidationError"
    ],
    "NetworkInactiveContactRemoveError": [
      "NetworkInactiveContactRemoveUnauthorizedError"
    ],
    "NewsUpdateError": [
      "NewsUpdateNotFoundError",
      "NewsUpdateSaveError",
      "NewsUpdateUnauthorizedError"
    ],
    "NewsroomBoilerplateCreateError": [
      "NewsroomBoilerplateCreateUnauthorizedError",
      "NewsroomBoilerplateCreateValidationError"
    ],
    "NewsroomBoilerplateDeleteError": [
      "NewsroomBoilerplateDeleteNotFoundError",
      "NewsroomBoilerplateDeleteUnauthorizedError"
    ],
    "NewsroomBoilerplateUpdateError": [
      "NewsroomBoilerplateUpdateNotFoundError",
      "NewsroomBoilerplateUpdateUnauthorizedError",
      "NewsroomBoilerplateUpdateValidationError"
    ],
    "NewsroomFeed": [
      "FacebookNewsroomFeed",
      "InstagramNewsroomFeed",
      "RssNewsroomFeed",
      "TwitterNewsroomFeed",
      "YoutubeNewsroomFeed"
    ],
    "NewsroomFeedDeleteError": [
      "NewsroomFeedDeleteNotFoundError",
      "NewsroomFeedDeleteUnauthorizedError"
    ],
    "NewsroomFeedFacebookChoosePageError": [
      "NewsroomFeedFacebookChoosePageNotFoundError",
      "NewsroomFeedFacebookChoosePageUnauthorizedError"
    ],
    "NewsroomFeedInputNeeded": [
      "FacebookNewsroomFeedChoosePage",
      "FacebookNewsroomFeedReauthenticate",
      "InstagramNewsroomFeedReauthenticate"
    ],
    "NewsroomFeedRssCreateError": [
      "NewsroomFeedRssCreateUnauthorizedError",
      "NewsroomFeedRssCreateValidationError"
    ],
    "NewsroomFeedTwitterCreateError": [
      "NewsroomFeedTwitterCreateUnauthorizedError",
      "NewsroomFeedTwitterCreateValidationError"
    ],
    "NewsroomFeedYoutubeCreateError": [
      "NewsroomFeedYoutubeCreateUnauthorizedError",
      "NewsroomFeedYoutubeCreateValidationError"
    ],
    "NewsroomReportError": [
      "NewsroomReportNoAccessError"
    ],
    "NewsroomSettingsUpdateError": [
      "NewsroomSettingsUpdateUnauthorizedError",
      "NewsroomSettingsUpdateValidationError"
    ],
    "NewsroomStylePreviewUpdateError": [
      "NewsroomStylePreviewUpdateUnauthorizedError",
      "NewsroomStylePreviewUpdateValidationError"
    ],
    "NewsroomViewsOverTime": [
      "NewsroomViewsOverTimeDate",
      "NewsroomViewsOverTimeInterval"
    ],
    "NpsAnswerError": [
      "NpsAnswerUnauthorizedError",
      "NpsAnswerValidationError"
    ],
    "PasswordChangeError": [
      "PasswordChangeUnauthorizedError",
      "PasswordChangeValidationError"
    ],
    "PasswordCheckError": [
      "PasswordCheckUnauthorizedError"
    ],
    "PasswordForgotError": [
      "PasswordForgotUnauthorizedError"
    ],
    "PitchDeleteError": [
      "PitchDeleteUnauthorizedError"
    ],
    "PitchSendError": [
      "PitchSendAlreadySentError",
      "PitchSendUnauthorizedError"
    ],
    "PitchState2": [
      "PitchClickedState",
      "PitchDraftState",
      "PitchMediaHitState",
      "PitchOpenedState",
      "PitchSentState",
      "PitchUnreachableState",
      "PitchUnsubscribed"
    ],
    "PitchTo": [
      "MndReachJournalist",
      "NetworkContact"
    ],
    "PitchUpdateError": [
      "PitchUpdateNotFoundError",
      "PitchUpdateSaveError",
      "PitchUpdateUnauthorizedError",
      "PitchUpdateValidationError"
    ],
    "PlanPrice": [
      "ContactUsPrice",
      "SelfServePrice"
    ],
    "PossibleHit": [
      "MonitorHit",
      "RestrictedMonitorHit"
    ],
    "PossibleQuotation": [
      "Quotation",
      "RestrictedQuotation"
    ],
    "PrImpactCreateHitError": [
      "PrImpactCreateHitNotFoundError",
      "PrImpactCreateHitValidationError"
    ],
    "PressReleaseUpdateError": [
      "PressReleaseUpdateNotFoundError",
      "PressReleaseUpdateSaveError",
      "PressReleaseUpdateUnauthorizedError"
    ],
    "PressroomGoLiveError": [
      "PressroomGoLiveNotFoundError",
      "PressroomGoLiveUnauthorizedError"
    ],
    "QuotationDeleteError": [
      "QuotationAuthorizationError",
      "QuotationNotFoundError"
    ],
    "QuotationSettingsError": [
      "QuotationSettingsMaterialNotFoundError",
      "QuotationSettingsValidationError"
    ],
    "RecipientDomainOrUnknown": [
      "RecipientDomain",
      "RecipientDomainUnknown"
    ],
    "RecommendedContactsAddToMaterialError": [
      "RecommendedContactsAddToMaterialUnauthorizedError",
      "RecommendedContactsAddToMaterialValidationError"
    ],
    "RegenerateSearchProfileError": [
      "RegenerateSearchProfileNotFoundError",
      "RegenerateSearchProfileUnauthorizedError",
      "RegenerateSearchProfileValidationError"
    ],
    "RemoveHitFromClipboardError": [
      "RemoveHitFromClipboardUnauthorizedError",
      "RemoveHitFromClipboardValidationError"
    ],
    "RemoveHitsError": [
      "RemoveHitsNotFoundError",
      "RemoveHitsUnauthorizedError"
    ],
    "ReportAddExternalCoverageError": [
      "ReportAddExternalCoverageFailedError",
      "ReportAddExternalCoverageNetworkError",
      "ReportAddExternalCoverageUnauthorizedError",
      "ReportAddExternalCoverageValidationError",
      "ReportAddExternalNoSubscriptionError"
    ],
    "ReportAddMonitorCoverageError": [
      "ReportAddMonitorCoverageFailedError",
      "ReportAddMonitorCoverageUnauthorizedError",
      "ReportAddMonitorCoverageValidationError",
      "ReportAddMonitorNoSubscriptionError"
    ],
    "ReportAddSectionError": [
      "ReportAddSectionNoSubscriptionError",
      "ReportAddSectionUnauthorizedError"
    ],
    "ReportAllSectionsInterface": [
      "ReportFeaturedSection",
      "ReportMenuSection",
      "ReportMetricsSection",
      "ReportPerceptionBenchmarkSection",
      "ReportPerceptionBenchmarkSentimentOverview",
      "ReportPerceptionSection",
      "ReportPerceptionSentimentOverTime",
      "ReportPerceptionSentimentOverview",
      "ReportQuotationFullWidthSection",
      "ReportQuotationGridSection",
      "ReportSectionInsights",
      "ReportSummaryCover",
      "ReportSummaryKeyMetrics",
      "ReportSummarySection",
      "ReportVisibilityBenchmarkSection",
      "ReportVisibilityBenchmarkShareOfVoice",
      "ReportVisibilityBenchmarkShareOfVoiceOverTime",
      "ReportVisibilityHighlights",
      "ReportVisibilityMentionsOverTime",
      "ReportVisibilityOverview",
      "ReportVisibilityReachOverTime",
      "ReportVisibilitySection"
    ],
    "ReportCancelImportJobError": [
      "ReportCancelImportJobUnauthorizedError"
    ],
    "ReportCoverageInterface": [
      "ReportExternalQuotation",
      "ReportMonitorQuotation",
      "ReportQuotation"
    ],
    "ReportCreateError": [
      "ReportCreateNoSubscriptionError",
      "ReportCreateUnauthorizedError",
      "ReportCreateUnknownResourceError",
      "ReportCreateValidationError"
    ],
    "ReportInterface": [
      "BrandReport",
      "CoverageReport"
    ],
    "ReportPerceptionBenchmarkSubSectionInterface": [
      "ReportPerceptionBenchmarkSentimentOverview",
      "ReportSectionInsights"
    ],
    "ReportPerceptionSubSectionInterface": [
      "ReportPerceptionSentimentOverTime",
      "ReportPerceptionSentimentOverview",
      "ReportSectionInsights"
    ],
    "ReportPopulateFeaturedError": [
      "ReportPopulateFeaturedFailedError",
      "ReportPopulateFeaturedNoSubscriptionError",
      "ReportPopulateFeaturedUnauthorizedError",
      "ReportPopulateFeaturedValidationError"
    ],
    "ReportRemoveCoverageError": [
      "ReportRemoveCoverageFailedError",
      "ReportRemoveCoverageNoSubscriptionError",
      "ReportRemoveCoverageUnauthorizedError"
    ],
    "ReportRemoveError": [
      "ReportRemoveUnauthorizedError"
    ],
    "ReportRemoveSectionError": [
      "ReportRemoveSectionNoSubscriptionError",
      "ReportRemoveSectionUnauthorizedError"
    ],
    "ReportRequestPdfSlideshowError": [
      "ReportRequestPdfSlideshowNoSubscriptionError",
      "ReportRequestPdfSlideshowUnauthorizedError"
    ],
    "ReportSectionInterface": [
      "ReportFeaturedSection",
      "ReportMenuSection",
      "ReportMetricsSection",
      "ReportPerceptionBenchmarkSection",
      "ReportPerceptionSection",
      "ReportQuotationFullWidthSection",
      "ReportQuotationGridSection",
      "ReportSummarySection",
      "ReportVisibilityBenchmarkSection",
      "ReportVisibilitySection"
    ],
    "ReportSetCoverageFeaturedError": [
      "ReportSetCoverageFeaturedFailedError",
      "ReportSetCoverageFeaturedNoSubscriptionError",
      "ReportSetCoverageFeaturedUnauthorizedError"
    ],
    "ReportSetCoverageHiddenError": [
      "ReportSetCoverageHiddenFailedError",
      "ReportSetCoverageHiddenNoSubscriptionError",
      "ReportSetCoverageHiddenUnauthorizedError"
    ],
    "ReportSetCoverageSentimentError": [
      "ReportSetCoverageSentimentFailedError",
      "ReportSetCoverageSentimentNoSubscriptionError",
      "ReportSetCoverageSentimentUnauthorizedError"
    ],
    "ReportSortCoverageError": [
      "ReportSortCoverageFailedError",
      "ReportSortCoverageNoSubscriptionError",
      "ReportSortCoverageUnauthorizedError"
    ],
    "ReportSortSectionError": [
      "ReportSortSectionFailedError",
      "ReportSortSectionNoSubscriptionError",
      "ReportSortSectionUnauthorizedError"
    ],
    "ReportSummarySubSectionInterface": [
      "ReportSectionInsights",
      "ReportSummaryCover",
      "ReportSummaryKeyMetrics"
    ],
    "ReportUpdateCoverageError": [
      "ReportUpdateCoverageFailedError",
      "ReportUpdateCoverageNoSubscriptionError",
      "ReportUpdateCoverageUnauthorizedError"
    ],
    "ReportUpdateError": [
      "ReportUpdateNoSubscriptionError",
      "ReportUpdateUnauthorizedError",
      "ReportUpdateUnknownResourceError",
      "ReportUpdateValidationError"
    ],
    "ReportUpdateInsightError": [
      "ReportUpdateInsightNoSubscriptionError",
      "ReportUpdateInsightUnauthorizedError",
      "ReportUpdateInsightValidationError"
    ],
    "ReportUpdatePrValueSettingsError": [
      "ReportUpdatePrValueSettingsFailedError",
      "ReportUpdatePrValueSettingsNoSubscriptionError",
      "ReportUpdatePrValueSettingsUnauthorizedError"
    ],
    "ReportUpdateSectionError": [
      "ReportUpdateSectionNoSubscriptionError",
      "ReportUpdateSectionUnauthorizedError",
      "ReportUpdateSectionValidationError"
    ],
    "ReportVisibilityBenchmarkSubSectionInterface": [
      "ReportSectionInsights",
      "ReportVisibilityBenchmarkShareOfVoice",
      "ReportVisibilityBenchmarkShareOfVoiceOverTime"
    ],
    "ReportVisibilitySubSectionInterface": [
      "ReportSectionInsights",
      "ReportVisibilityHighlights",
      "ReportVisibilityMentionsOverTime",
      "ReportVisibilityOverview",
      "ReportVisibilityReachOverTime"
    ],
    "ReportsGenerateSectionDescriptionPromptDataError": [
      "ReportsGenerateSectionDescriptionPromptDataEmptySectionError",
      "ReportsGenerateSectionDescriptionPromptDataNoSubscriptionError",
      "ReportsGenerateSectionDescriptionPromptDataUnauthorizedError"
    ],
    "RequestDemoError": [
      "RequestDemoRequestError",
      "RequestDemoUnauthorizedError",
      "RequestDemoValidationError"
    ],
    "ResourceImageUpdateError": [
      "ResourceImageUpdateNotFoundError",
      "ResourceImageUpdateSaveError",
      "ResourceImageUpdateUnauthorizedError"
    ],
    "SearchError": [
      "SearchTimeoutError"
    ],
    "SendClipboardEmailError": [
      "SendClipboardEmailNotFoundError",
      "SendClipboardEmailUnauthorizedError"
    ],
    "SendExclusiveError": [
      "SendExclusiveNotLiveError",
      "SendExclusiveUnauthorizedError",
      "SendExclusiveValidationError"
    ],
    "SocialChannel": [
      "FacebookChannel",
      "LinkedinChannel",
      "TwitterChannel",
      "YoutubeChannel"
    ],
    "SourceToggle2faError": [
      "SourceToggle2faUnauthorizedError"
    ],
    "SourceToggleFollowerNotificationsError": [
      "SourceToggleFollowerNotificationsUnauthorizedError"
    ],
    "StoryIdeaLikeError": [
      "StoryIdeaLikeNotFoundError",
      "StoryIdeaLikeUnauthorizedError"
    ],
    "StoryMaterial": [
      "BlogPost",
      "News",
      "Pressrelease"
    ],
    "StoryWidgetUpdateError": [
      "StoryWidgetUpdateUnauthorizedError",
      "StoryWidgetUpdateValidationError"
    ],
    "TagCombineError": [
      "TagCombineNotFoundError",
      "TagCombineUnauthorizedError",
      "TagCombineValidationError"
    ],
    "TagDeleteError": [
      "TagDeleteUnauthorizedError"
    ],
    "TagMoveHighlightedError": [
      "TagMoveHighlightedNotFoundError",
      "TagMoveHighlightedUnauthorizedError"
    ],
    "TagUntagMaterialsError": [
      "TagUntagMaterialsNotFoundError",
      "TagUntagMaterialsUnauthorizedError"
    ],
    "TagUpdateError": [
      "TagUpdateNotFoundError",
      "TagUpdateUnauthorizedError",
      "TagUpdateValidationError"
    ],
    "TwoFactorDisableError": [
      "TwoFactorDisableUnauthorizedError",
      "TwoFactorDisableValidationError"
    ],
    "TwoFactorEnableError": [
      "TwoFactorEnableAlreadyActiveError",
      "TwoFactorEnableUnauthorizedError",
      "TwoFactorEnableValidationError"
    ],
    "TwoFactorRenewRecoveryCodesError": [
      "TwoFactorRenewRecoveryCodesUnauthorizedError",
      "TwoFactorRenewRecoveryCodesValidationError"
    ],
    "UpdateClipboardError": [
      "UpdateClipboardNotFoundError",
      "UpdateClipboardUnauthorizedError",
      "UpdateClipboardValidationError"
    ],
    "UpdateClipboardHitsError": [
      "UpdateClipboardHitsNotFoundError",
      "UpdateClipboardHitsUnauthorizedError",
      "UpdateClipboardHitsValidationError"
    ],
    "UpdateHit2Error": [
      "UpdateHit2FailedError",
      "UpdateHit2NotFoundError",
      "UpdateHit2UnauthorizedError"
    ],
    "UpdateHitError": [
      "UpdateHitFailedError",
      "UpdateHitNotFoundError",
      "UpdateHitUnauthorizedError"
    ],
    "UpdateSearchProfileError": [
      "UpdateSearchProfileUnauthorizedError",
      "UpdateSearchProfileValidationError"
    ],
    "UserAccessDeleteError": [
      "UserAccessDeleteAccountOwnerError",
      "UserAccessDeleteNotFoundError",
      "UserAccessDeleteUnauthorizedError"
    ],
    "UserActivationEmailResendError": [
      "UserActivationEmailResendActivatedError",
      "UserActivationEmailResendAuthError"
    ],
    "UserActivationEmailUpdateError": [
      "UserActivationEmailUpdateAuthError",
      "UserActivationEmailUpdateValidationError"
    ],
    "UserInvitationCreateError": [
      "UserInvitationCreateUnauthorizedAdminError",
      "UserInvitationCreateUnauthorizedError",
      "UserInvitationCreateValidationError"
    ],
    "UserInvitationDeleteError": [
      "UserInvitationDeleteUnauthorizedError"
    ],
    "UserMaterialEmailInfoInterface": [
      "UserMaterialEmailInfo",
      "UserUnknown"
    ],
    "UserOrUnknown": [
      "User",
      "UserUnknown"
    ],
    "UserPromoteToOwnerError": [
      "UserPromoteToOwnerNotFoundError",
      "UserPromoteToOwnerUnauthorizedError"
    ],
    "UserRemoveSelfError": [
      "UserRemoveSelfUnauthorizedError",
      "UserRemoveSelfValidationError"
    ],
    "UserUpdateError": [
      "UserUpdateUnauthorizedError",
      "UserUpdateValidationError"
    ],
    "VideoCreateScreen9UploadLinkError": [
      "VideoCreateScreen9UploadLinkUnauthorizedError"
    ],
    "VideoProgressUpdateError": [
      "VideoProgressUpdateValidationError"
    ],
    "VideoRemoveResourceVideoError": [
      "VideoRemoveResourceVideoNotFoundError",
      "VideoRemoveResourceVideoSaveError",
      "VideoRemoveResourceVideoUnauthorizedError"
    ],
    "VideoSyncScreen9UploadError": [
      "VideoSyncScreen9UploadNotFoundError",
      "VideoSyncScreen9UploadUnauthorizedError",
      "VideoSyncScreen9UploadValidationError"
    ],
    "VideoUpdateError": [
      "VideoUpdateNotFoundError",
      "VideoUpdateSaveError",
      "VideoUpdateUnauthorizedError"
    ],
    "VideoUpdateThumbnailError": [
      "VideoUpdateThumbnailInvalidArgumentError",
      "VideoUpdateThumbnailUnauthorizedError"
    ],
    "WithImageUnion": [
      "BlogPost",
      "BrandReport",
      "ContactPerson2",
      "CoverageReport",
      "Document",
      "Event",
      "LinkedinPost",
      "News",
      "Pressrelease",
      "Tag",
      "Video"
    ]
  }
};
      export default result;
    