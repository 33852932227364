/**
 * This is a typed abstraction upon Segments Analytics.js. All events
 * will be forwarded to Segment which then forwards them to trackers
 * or other destinations.
 *
 * Events needing some specific handling or that needs to be sent to a
 * separate tracker/endpoint which for some reason can't be en segment
 * should be added in its switch case.
 *
 * https://segment.com/
 */
import { StrictExtends } from '@mnd-frontend/ts';
import { withOneTrust } from '@segment/analytics-consent-wrapper-onetrust';
import { AnalyticsBrowser } from '@segment/analytics-next';
import Debug from 'debug';
import { merge } from 'lodash-es';

const debug = Debug('mnd:tracker:debug');

interface Input {
  event: string;
  traits?: object;
}

export interface IdentifyEvent {
  event: 'Identify';
  traits: {
    id: string;
  };
}

export interface NativePageEvent {
  event: 'Native Page';
}

export interface NativePageErrorEvent {
  event: 'Native Page Error';
}

export const initTracking = <
  input extends Input,
  identify extends IdentifyEvent = IdentifyEvent,
  nativePageEvent extends NativePageEvent = NativePageEvent,
  nativePageErrorEvent extends NativePageErrorEvent = NativePageErrorEvent,
>({
  application,
  superTraits,
  writeKey,
  waitForOneTrust,
}: {
  application: 'publish' | 'reports' | 'public';
  superTraits?: () => object | undefined;
  writeKey?: string;
  waitForOneTrust?: boolean;
}) => {
  if (typeof window === 'undefined') {
    return () => undefined;
  }
  const analytics = new AnalyticsBrowser();

  if (writeKey) {
    if (waitForOneTrust) {
      debug(`Tracking is enabled with onetrust integration`);
      withOneTrust(analytics, { consentModel: () => 'opt-in' }).load({ writeKey });
    } else {
      analytics.load({ writeKey });
      debug(`Tracking is enabled`);
    }
  } else {
    debug(`Tracking is not enabled`);
  }

  return (
    input: StrictExtends<input, Input> | identify | nativePageEvent | nativePageErrorEvent,
  ): void => {
    const traitsWithGlobal = merge(
      {},
      superTraits?.() || {},
      ('traits' in input && input.traits) || {},
      { application },
    );

    debug(`${input.event} %o`, traitsWithGlobal);

    switch (input.event) {
      case 'Identify': {
        analytics.identify((input as { traits?: { id?: string } })?.traits?.id, traitsWithGlobal);
        break;
      }
      case 'Native Page': {
        analytics.page(application, undefined, traitsWithGlobal);
        break;
      }
      case 'Native Page Error': {
        analytics.page(application, undefined, { ...traitsWithGlobal, hasError: true });
        break;
      }
      default: {
        analytics.track(input.event, traitsWithGlobal);
        break;
      }
    }
  };
};
