import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { selectContentClickHandler } from '../../../lib/tracker';
import { formatUrl } from '../../../utils/formatUrl';
import { RelatedPostsFragment } from '../../../wp-generated/types';
import LinkButton from '../../LinkButton';
import { PostTag } from '../../PostTag';
import { chooseLightOrDarkTextColor, moduleBackgroundToCSS } from '../getModuleColors';

const Container = styled.section<{ $background: string; $color: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${({ $background }) => $background};
  color: ${({ $color }) => $color};
  padding: 1.25rem;
  @media (min-width: 1080px) {
    padding: var(--spacing-2x-large) var(--site-padding);
  }
`;

const Title = styled.h2`
  font-weight: var(--font-weight-extra-bold);
  text-align: center;
  width: 100%;
  letter-spacing: 1px;
  line-height: calc(var(--font-size-large) + 0.3125rem);
  text-transform: uppercase;
  color: inherit;
`;

const ArticleList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: center;
  gap: 1.5rem;
  @media (min-width: 1080px) {
    gap: 2.5rem;
  }
`;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  h3 {
    margin: 0;
  }
  a {
    color: inherit;
  }
  a:hover {
    text-decoration-line: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;
  }
  a.linkButton {
    transform: translateX(-1rem);
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;

const PostTags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const PostTitle = styled.h3`
  font-size: var(--font-size-x-large);
  font-weight: var(--font-weight-bold);
  line-height: calc(var(--font-size-x-large) + 0.3125rem);
  word-break: break-word;
  hyphens: auto;
`;

const RelatedPosts = ({ moduleData }: { moduleData: RelatedPostsFragment }) => {
  const { t } = useTranslation();
  return (
    <Container
      $background={moduleBackgroundToCSS(moduleData?.bgColor)}
      $color={chooseLightOrDarkTextColor(moduleData?.bgColor)}
    >
      <Title>{moduleData.title}</Title>
      {moduleData.data && moduleData.data.length > 0 && (
        <ArticleList>
          {moduleData.data.map((post, index) => (
            <Article key={index}>
              <ImgWrapper>
                <Img src={post?.image || ''} alt={post?.image || ''} />
              </ImgWrapper>
              <PostTags>
                {post?.tags?.map((tag, index) => (
                  <PostTag
                    key={index}
                    href={formatUrl(tag?.url)}
                    $background={moduleBackgroundToCSS(moduleData.bgColor)}
                    onClick={selectContentClickHandler(tag?.name ?? '', 'tag')}
                  >
                    {tag?.name}
                  </PostTag>
                ))}
              </PostTags>
              <PostTitle>{post?.title}</PostTitle>
              <LinkButton
                href={formatUrl(post?.url)}
                variant="text"
                className="linkButton"
                onClick={selectContentClickHandler(post?.title ?? '', 'post')}
              >
                {t('common_read-more')}
              </LinkButton>
            </Article>
          ))}
        </ArticleList>
      )}
    </Container>
  );
};

export default RelatedPosts;
