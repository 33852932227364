import { IdentifyEvent, initTracking, NativePageEvent } from '@mnd-frontend/tracking';
import { MouseEventHandler } from 'react';

export interface FormSubmissionEvent {
  event:
    | 'M&R Trial Signup - Form Submitted'
    | 'Product Tour Trial Signup - Form Submitted'
    | 'Trial Signup - Form Submitted';
  traits: {
    market?: string;
  };
}

export interface DemoRequestFormSubmissionEvent {
  event: 'Product Tour Demo Request - Form Submitted';
}

interface HeaderStartTrialEvent {
  event: 'BR landing page - Clicked Start trial';
  traits: {
    brand: string;
  };
}

interface FooterStartTrialEvent {
  event: 'BR Trial signup - Clicked CTA';
  traits: {
    reportId: string;
    brand: string;
  };
}

interface CreateReportEvent {
  event: 'BR landing page - Clicked Create report';
  traits: {
    brand: string;
    market: string;
  };
}

interface GetFullReportEvent {
  event: 'BR landing page - Clicked Get full report';
  traits: {
    brand: string;
    market: string;
  };
}

interface ThankYouPageStartTrialEvent {
  event: 'BR landing page - Clicked Thank you page CTA';
  traits: {
    brand: string;
  };
}

interface PageEvent {
  event: 'Page';
  traits: {
    path: string;
    applicationLanguage: string;
  };
}

interface CustomTourEvent {
  event: 'CustomTourPageView';
  traits: {
    salesRepName: string;
    prospectCompany: string;
  };
}

export type UserInteraction =
  | 'main_navigation_click'
  | 'footer_navigation_click'
  | 'search'
  | 'filter_interaction'
  | 'lead_generation'
  | 'button_click'
  | 'cta_click'
  | 'customer_case_click'
  | 'blog_post_click'
  | 'resource_click'
  | 'event_click';

interface DatalayerPageview {
  /** A unique identifier for each event */
  event: 'page_view';
  traits: {
    event_info: {
      /** The name of the page (e.g, startpage, productpage, blog) */
      page_name?: string;
      /** The referal link from previous page */
      page_referrer: string;
      /** The type of page (e.g, start_page) */
      page_type?: string;
    };
  };
}
interface DatalayerMainNavigation {
  event: 'main_navigation';
  /** A unique identifier for each event */
  traits: {
    event_info: {
      /** Used on smaller devices, the values could be open or closed */
      burger_menu: 'open' | 'closed';
      /** The type of interaction (e.g., click on CTA's) */
      user_interaction: UserInteraction;
      /** The text or label on the menu button */
      navigation_selection: string;
      /** The cateogry of the navigation */
      navigation_category: string;
    };
  };
}
interface DatalayerFooterNavigation {
  event: 'footer_navigation';
  /** A unique identifier for each event */
  traits: {
    event_info: {
      /** The type of interaction (e.g., click on CTA's) */
      user_interaction: UserInteraction;
      /** The text or label on the footer button/link */
      navigation_selection: string;
      /** The cateogry of the navigation */
      navigation_category: string;
    };
  };
}
interface DatalayerVideoStart {
  event: 'video_start';
  /** A unique identifier for each event */
  traits: {
    event_info: {
      /** The title/label of the video */
      video_title: string;
      /** The uniqe id of the video if available */
      video_id?: string;
      /** The length of the video in sec */
      video_length: number;
    };
  };
}
interface DatalayerVideoProgress {
  event: 'video_progress';
  /** A unique identifier for each event */
  traits: {
    event_info: {
      /** The title/label of the video */
      video_title: string;
      /** The uniqe id of the video if available  */
      video_id: string;
      /** The length of the video in sec */
      video_length: string;
      /** The percent of the video progressed */
      progress_percent: number;
    };
  };
}
interface DatalayerVideoComplete {
  event: 'video_complete';
  /** A unique identifier for each event */
  traits: {
    event_info: {
      /** The title/label of the video */
      video_title: string;
      /** The uniqe id of the video if available */
      video_id: string;
      /** The length of the video in sec */
      video_length: string;
    };
  };
}
interface DatalayerCtaClick {
  event: 'cta_click';
  /** A unique identifier for each event */
  traits: {
    event_info: {
      /** The type of interaction (e.g., click on CTA's) */
      user_interaction: UserInteraction;
      /** The text or label on the CTA button */
      navigation_selection: string;
      /** The url which the cta links to */
      page_location: string;
      /** The category of the page that the CTA is located on (e.g, Contact) */
      category?: string;
      /** The position of the cta on the page (e.g, hero_selection) */
      position: string;
    };
  };
}
interface DatalayerScroll {
  event: 'scroll';
  /** A unique identifier for each event */
  traits: {
    event_info: {
      /** The percentage of the page that has been scrolled */
      scroll_depth: number;
      /** The height of the viewport in pixels */
      viewport_height: number;
      /** The height of the document in pixels */
      document_height: number;
    };
  };
}
interface DatalayerShareContentIntention {
  event: 'share_content_intention';
  /** A unique identifier for each event */
  traits: {
    event_info: {
      /** A unique identifier for the article */
      article_id: string;
      /** The title of the article */
      article_name: string;
      /** The method used to share the article (e.g., copy_link, Facebook, Twitter, or LinkedIn etc.) */
      share_method: string;
      /** The position of the share button on the page */
      position: string;
    };
  };
}
interface DatalayerFileDownload {
  event: 'file_download';
  /** A unique identifier for each event */
  traits: {
    event_info: {
      /** The type of file downloaded */
      file_type: string;
      /** The title of the file downoaded */
      file_name: string;
    };
  };
}
interface DatalayerError {
  event: 'error';
  /** A unique identifier for each event */
  traits: {
    event_info: {
      /** The type of error that occurred */
      error_type: string;
    };
  };
}
interface DatalayerErrorFormSubmit {
  event: 'error_form_submit';
  /** A unique identifier for each event */
  traits: {
    event_info: {
      /** The type of interaction (e.g., click on CTA's) */
      user_interaction: UserInteraction;
      /** The text or label on the CTA button */
      navigation_selection: string;
      /** The id of the form where the event occurred */
      form_id: string;
      /** The name of the form where the event occurred */
      form_name: string;
      /** The the field in the form that triggered the event */
      form_field: string;
      /** The message that was given when the error event occurred */
      error_message: string;
      /** The code connected to the error_message if the message is to long */
      error_code: string;
    };
  };
}
interface DatalayerSearch {
  event: 'search';
  /** A unique identifier for each event */
  traits: {
    event_info: {
      /** The type of interaction (e.g., click on CTA's) */
      user_interaction: UserInteraction;
      /** The text or label on the CTA button */
      navigation_selection: string;
      /** The term that was written during a search */
      search_term: string;
      /** The amount of results given due to a search */
      search_results: string;
    };
  };
}
interface DatalayerSiteSearchResultClick {
  event: 'site_search_result_click';
  /** A unique identifier for each event */
  traits: {
    event_info: {
      /** The type of interaction (e.g., click on CTA's) */
      user_interaction: UserInteraction;
      /** The text or label on the CTA button */
      navigation_selection: string;
      /** The term that was written during a search */
      search_term: string;
    };
  };
}
interface DatalayerFilterInteraction {
  event: 'filter_interaction';
  /** A unique identifier for each event */
  traits: {
    event_info: {
      /** The type of interaction (e.g., click on CTA's) */
      user_interaction: UserInteraction;
      /** The text or label on the CTA button */
      navigation_selection: string;
      /** The name of the filter/block where the event occurred */
      filter_name: string;
      /** The type of filter that was used */
      filter_type: string;
    };
  };
}
interface DatalayerFormSubmit {
  event: 'form_submit';
  /** A unique identifier for each event */
  traits: {
    event_info: {
      /** The type of interaction (e.g., click on CTA's) */
      user_interaction: UserInteraction;
      /** The text or label on the CTA button */
      navigation_selection: string;
      /** The id of the form where the event occurred */
      form_id: string;
      /** The name of the form where the event occurred */
      form_name: string;
    };
  };
}
interface DatalayerLeadGeneration {
  event: 'lead_generation';
  /** A unique identifier for each event */
  traits: {
    event_info: {
      /** The id of the form where the event occurred */
      form_id: string;
      /** The name of the form where the event occurred */
      form_name: string;
      /** The type of interaction (e.g., click on CTA's) */
      user_interaction: UserInteraction;
      /** The text or label on the CTA button */
      navigation_selection: string;
      /** Where the lead generated from */
      lead_source: string;
    };
  };
}
interface DatalayerSelectContent {
  event: 'select_content';
  /** A unique identifier for each event */
  traits: {
    event_info: {
      /** An identifier for the content that was selected. */
      content_id: string;
      /**  The type of selected content. */
      content_type: string;
      /** The type of page (e.g, start_page) */
      page_type?: string;
    };
  };
}

interface DataLayerLinkClick {
  event: 'link_click';
  traits: {
    event_info: {
      link_title: string;
    };
  };
}

const eventId = () => {
  try {
    return self.crypto.randomUUID();
  } catch (e) {
    return Math.random().toString(16).slice(2);
  }
};

export const onFormInvalid = (form_id: string, form_name: string, navigation_selection: string) => {
  return (e: any, errorMessage?: string | null) => {
    const invalidField = e.target as HTMLInputElement;
    const formField =
      invalidField.placeholder || invalidField.name || invalidField.id || 'unknown_field'; // Field identifier
    errorMessage ||= invalidField.validationMessage;
    let errorCode = 'unknown_error';
    for (const key in invalidField.validity) {
      if (invalidField.validity[key as keyof ValidityState]) {
        errorCode = key;
      }
    }
    track({
      event: 'error_form_submit',
      traits: {
        event_info: {
          form_id,
          form_name,
          user_interaction: 'button_click',
          navigation_selection,
          form_field: formField,
          error_message: errorMessage,
          error_code: errorCode,
        },
      },
    });
  };
};

export const selectContentClickHandler = (content_id: string, content_type: string) => {
  return (() => {
    track({
      event: 'select_content',
      traits: {
        event_info: {
          content_id,
          content_type,
        },
      },
    });
  }) as MouseEventHandler<HTMLAnchorElement>;
};

export const ctaClickHandler = (position: string) => {
  return (e => {
    track({
      event: 'cta_click',
      traits: {
        event_info: {
          user_interaction: 'cta_click',
          navigation_selection: (e.target as HTMLElement).textContent ?? '',
          page_location: window.location.href,
          position,
        },
      },
    });
  }) as MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
};

type TrackInput =
  | FormSubmissionEvent
  | HeaderStartTrialEvent
  | FooterStartTrialEvent
  | CreateReportEvent
  | GetFullReportEvent
  | ThankYouPageStartTrialEvent
  | PageEvent
  | DemoRequestFormSubmissionEvent
  | CustomTourEvent
  | DatalayerPageview
  | DatalayerMainNavigation
  | DatalayerFooterNavigation
  | DatalayerVideoStart
  | DatalayerVideoProgress
  | DatalayerVideoComplete
  | DatalayerCtaClick
  | DatalayerScroll
  | DatalayerShareContentIntention
  | DatalayerFileDownload
  | DatalayerError
  | DatalayerErrorFormSubmit
  | DatalayerSearch
  | DatalayerSiteSearchResultClick
  | DatalayerFilterInteraction
  | DatalayerFormSubmit
  | DatalayerLeadGeneration
  | DatalayerSelectContent
  | DataLayerLinkClick;

export const track = initTracking<
  TrackInput,
  IdentifyEvent,
  NativePageEvent & {
    traits: {
      applicationLanguage: string;
    };
  }
>({
  application: 'public',
  writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
  waitForOneTrust: !!process.env.NEXT_PUBLIC_ONETRUST_DOMAIN_ID,
  superTraits: () => ({
    event_id: eventId(),
    event_info: {
      app_version: process.env.NEXT_PUBLIC_APP_VERSION || 'local',
      page_title: document.title,
      page_path: window.location.pathname,
    },
  }),
});
