import { createContext, ReactNode, useContext } from 'react';
import { Path } from 'react-router-dom';
import { DefaultTheme } from '../theme';
import { ToastsContextProvider } from './ToastsContext';

const UIContext = createContext<UIContextValue>({
  useNavigate: () => location => {
    console.error('NO UIContextProvider defined!');
    const target =
      typeof location === 'string'
        ? location
        : `${location.pathname}${location.hash ?? ''}${location.search ?? ''}`;
    window.location.replace(target);
  },
  useLocation: () => {
    console.error('NO UIContextProvider defined!');
    return {
      hash: window.location.hash,
      pathname: window.location.pathname,
    } as Path;
  },
  useTranslation: () => ({ t: (s: string) => s }),
});

export interface UIContextValue {
  useNavigate: () => (location: Partial<Path> | string, options?: { replace?: boolean }) => void;
  useLocation: () => { pathname: string; hash: string };
  useTranslation: () => { t: (key: string, args?: Record<string, string | number>) => string };
}

export const UIContextProvider = ({
  value,
  children,
}: {
  value: UIContextValue;
  children: ReactNode;
}) => {
  return (
    <UIContext.Provider value={value}>
      <DefaultTheme>
        <ToastsContextProvider>{children}</ToastsContextProvider>
      </DefaultTheme>
    </UIContext.Provider>
  );
};

export const useLocation = () => useContext(UIContext).useLocation();
export const useNavigate = () => useContext(UIContext).useNavigate();
export const useTranslation = () => useContext(UIContext).useTranslation();
