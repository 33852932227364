import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ctaClickHandler, selectContentClickHandler } from '../../../lib/tracker';
import { formatUrl } from '../../../utils/formatUrl';
import {
  FeaturedPagesFragment,
  FeaturedPostsFragment,
  ImageFragment,
  SiteSettingsFeaturedPagesFragment,
} from '../../../wp-generated/types';
import LinkButton from '../../LinkButton';
import PostImage from '../../PostImage';
import { PostTag } from '../../PostTag';
import {
  chooseButtonColorBasedOnBackground,
  chooseLightOrDarkTextColor,
  moduleBackgroundToCSS,
} from '../getModuleColors';

const ModuleContainer = styled.section<{ $background: string; $color: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ $background }) => $background};
  color: ${({ $color }) => $color};
  gap: 1rem;
  padding: 1.25rem;
  @media (min-width: 1080px) {
    padding: var(--spacing-2x-large) var(--site-padding);
  }
  h2 {
    font-size: var(--font-size-large);
    margin: 0 auto;
    font-weight: var(--font-weight-extra-bold);
    text-transform: uppercase;
  }
`;

const ArticleList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-content: center;
  gap: 1.5rem;
  @media (min-width: 1080px) {
    gap: 2rem;
  }
`;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  a {
    width: fit-content;
  }
  h3 {
    margin: 0;
  }
`;

const PostTags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const PostTitle = styled.h3`
  font-size: var(--font-size-x-large);
  font-weight: var(--font-weight-bold);
  line-height: 29px;
  margin-bottom: var(--spacing-small);
  word-break: break-word;
  hyphens: auto;
`;

const PostDate = styled.time`
  font-weight: var(--font-weight-bold);
  line-height: 22px;
`;

const PostLink = styled.a`
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-semi-bold);
  line-height: 25px;
  color: var(--link-color);

  &:hover {
    text-decoration: underline;
  }
`;

const ModuleFooter = styled.footer`
  display: flex;
  justify-content: center;
`;

export const FeaturedPages = ({
  moduleData,
}: {
  moduleData: FeaturedPagesFragment | SiteSettingsFeaturedPagesFragment | null;
}) => {
  const { t } = useTranslation();
  const links = (moduleData as FeaturedPagesFragment)?.links || [];
  return (
    <ModuleContainer
      $background={moduleBackgroundToCSS(moduleData?.bgColor)}
      $color={chooseLightOrDarkTextColor(moduleData?.bgColor)}
    >
      {moduleData?.title && <h2>{moduleData?.title}</h2>}
      <ArticleList>
        {moduleData?.rows?.nodes.map(
          (page, index) =>
            page.__typename === 'Page' && (
              <Article key={index}>
                {page?.featuredImage?.node && (
                  <PostImage data={page.featuredImage.node as ImageFragment} />
                )}
                <PostTitle>
                  <a
                    href={formatUrl(page?.link)}
                    onClick={selectContentClickHandler(page.id, 'page')}
                  >
                    {page?.title}
                  </a>
                </PostTitle>
                {page?.link && (
                  <PostLink
                    href={formatUrl(page?.link)}
                    onClick={selectContentClickHandler(page.id, 'page')}
                  >
                    {t('common_read-more')}
                  </PostLink>
                )}
              </Article>
            ),
        )}
      </ArticleList>
      {links && links.length > 0 && (
        <ModuleFooter>
          {links.map((link, i) => (
            <LinkButton
              key={link?.link?.url || i}
              href={formatUrl(link?.link?.url)}
              target={link?.link?.target || undefined}
              variant={chooseButtonColorBasedOnBackground(moduleData?.bgColor)}
              onClick={ctaClickHandler('featured_pages')}
            >
              {link?.link?.title}
            </LinkButton>
          ))}
        </ModuleFooter>
      )}
    </ModuleContainer>
  );
};

export const FeaturedPosts = ({ moduleData }: { moduleData: FeaturedPostsFragment | null }) => {
  const { t } = useTranslation();

  return (
    <ModuleContainer
      $background={moduleBackgroundToCSS(moduleData?.bgColor)}
      $color={chooseLightOrDarkTextColor(moduleData?.bgColor)}
    >
      <h2>{moduleData?.title}</h2>
      <ArticleList>
        {moduleData?.rows?.nodes.map(
          (page, index) =>
            page.__typename === 'Post' && (
              <Article key={index}>
                <PostImage data={page?.featuredImage?.node} />
                <PostTags>
                  {page?.categories?.nodes.map((category, index) => (
                    <PostTag
                      key={index}
                      href={formatUrl(category.link)}
                      $background={moduleBackgroundToCSS(moduleData?.bgColor)}
                      onClick={selectContentClickHandler(category.name ?? '', 'category')}
                    >
                      {category.name}
                    </PostTag>
                  ))}
                </PostTags>
                <h3>
                  <a href={formatUrl(page?.link)}>{page?.title}</a>
                </h3>
                {page?.date && (
                  <PostDate dateTime={page?.date}>{page?.date.split('T')[0]}</PostDate>
                )}
                {page?.link && (
                  <PostLink
                    href={formatUrl(page?.link)}
                    onClick={selectContentClickHandler(page.id, 'post')}
                  >
                    {t('common_read-more')}
                  </PostLink>
                )}
              </Article>
            ),
        )}
      </ArticleList>
      {moduleData?.links && moduleData.links.length > 0 && (
        <ModuleFooter>
          {moduleData.links.map((link, i) => (
            <LinkButton
              key={link?.link?.url || i}
              href={formatUrl(link?.link?.url)}
              target={link?.link?.target || undefined}
              variant={chooseButtonColorBasedOnBackground(moduleData.bgColor)}
              onClick={ctaClickHandler('featured_posts')}
            >
              {link?.link?.title}
            </LinkButton>
          ))}
        </ModuleFooter>
      )}
    </ModuleContainer>
  );
};
